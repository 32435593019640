import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

import { Typography, styled } from "@mui/material";

import {
  SavedSearchPanelProps,
  SavedSearchState,
} from "../../../types/panels/savedSearchPanel/component";
import { GetAllSavedSearchesResponse } from "../../../types/panels/savedSearchPanel/savedSearchData";

import { SAVED_SEARCH_STATE_ACTIONS } from "../../../constants/panels/savedSearchPanel/component";

import useDataGridStore from "../../../store/grid/dataGridStore";

import useGetAllSavedSearches from "../../../customHooks/savedSearch/useGetAllSavedSearches";

import { savedSearchStateReducer } from "../../../reducers/savedSearch";
import Loading from "../../common/Loading";
import PanelDrawer from "../common/PanelDrawer";
import SavedSearchList from "./SavedSearchList";

const SavedSearchContent = styled("div")(({ height }: { height: string }) => ({
  height,
}));

const SavedSearchPanel: FC<SavedSearchPanelProps> = ({
  open,
  getSavedSearch,
  handleCloseMenuClick,
}) => {
  const showGrid = useDataGridStore((state) => state.showGrid);
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const isExpandGridWidth = useDataGridStore(
    (state) => state.isExpandGridWidth
  );

  const [savedSearchList, setSavedSearchList] =
    useState<GetAllSavedSearchesResponse>([]);
  const [savedSearchState, savedSearchStateDispatch] = useReducer(
    savedSearchStateReducer,
    {}
  );

  const {
    isLoading: savedSearchListLoading,
    data: savedSearchListData,
    error: savedSearchListError,
    getAllSavedSearches,
  } = useGetAllSavedSearches();

  useEffect(() => {
    if (open) {
      savedSearchStateDispatch({ type: SAVED_SEARCH_STATE_ACTIONS.CLEAR });
      getAllSavedSearches();
    }
  }, [open]);

  useEffect(() => {
    if (
      savedSearchListLoading ||
      !savedSearchListData ||
      savedSearchListError
    ) {
      return;
    }
    setSavedSearchList(savedSearchListData);

    // generate default states for every saved search item
    const newSavedSearchState: SavedSearchState = {};
    // TODO: Remove the type after converting the save search hook into ts
    savedSearchListData.forEach((item) => {
      newSavedSearchState[item.id] = {
        onDeleteMode: false,
        isDeleting: false,
      };
    });
    savedSearchStateDispatch({
      type: SAVED_SEARCH_STATE_ACTIONS.SET,
      payload: {
        savedSearchState: newSavedSearchState,
      },
    });
  }, [savedSearchListLoading, savedSearchListData]);

  const handleDeleteClick = useCallback((id: number) => {
    savedSearchStateDispatch({
      type: SAVED_SEARCH_STATE_ACTIONS.DELETE_MODE,
      payload: { id, status: true },
    });
  }, []);

  const handleCancelClick = useCallback((id: number) => {
    savedSearchStateDispatch({
      type: SAVED_SEARCH_STATE_ACTIONS.DELETE_MODE,
      payload: { id, status: false },
    });
  }, []);

  const deletingCallback = useCallback((id: number) => {
    savedSearchStateDispatch({
      type: SAVED_SEARCH_STATE_ACTIONS.DELETING,
      payload: { id },
    });
  }, []);

  const deletedCallback = useCallback((deletedSavedId: number) => {
    setSavedSearchList((prev) => [
      ...prev.filter((savedSearch) => savedSearch.id !== deletedSavedId),
    ]);
    savedSearchStateDispatch({
      type: SAVED_SEARCH_STATE_ACTIONS.DELETED,
      payload: { id: deletedSavedId },
    });
  }, []);

  const getContentHeight: string = useMemo(() => {
    let height = "calc(100vh - 128px)";

    if (isExpandGridWidth) {
      if (showGrid) {
        height = `calc(100vh - (${gridHeight} + 128px))`;
      } else if (!showGrid && showGridHeader) {
        height = "calc(100vh - 168px)";
      }
    }

    return height;
  }, [gridHeight, showGrid, showGridHeader, isExpandGridWidth]);

  return (
    <PanelDrawer
      open={open}
      className="saved-search-panel"
      handleCloseMenuClick={handleCloseMenuClick}
    >
      <div className="drawer-container">
        <div className="sub-header">
          <Typography variant="h5">MY SAVED SEARCHES</Typography>
        </div>
        <SavedSearchContent
          className="saved-search-content"
          height={getContentHeight}
        >
          {savedSearchListLoading ? (
            <div className="d-flex justify-content-center py-5">
              <Loading />
            </div>
          ) : (
            <SavedSearchList
              savedSearchList={savedSearchList}
              savedSearchState={savedSearchState}
              getSavedSearch={getSavedSearch}
              handleDeleteClick={handleDeleteClick}
              handleCancelClick={handleCancelClick}
              deletingCallback={deletingCallback}
              deletedCallback={deletedCallback}
            />
          )}
        </SavedSearchContent>
      </div>
    </PanelDrawer>
  );
};
export default SavedSearchPanel;
