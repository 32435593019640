import { useState } from "react";

import axios from "axios";

import {
  BaseSearchRequestNoPaginationPayload,
  SearchRequestPayload,
  SearchRequestWithShapes,
} from "../../../types/common/api";
import { BaseSearchCriteria } from "../../../types/common/search";
import { WellData } from "../../../types/common/wells";
import { WellDataResponse } from "../../../types/grid";
import { GetWellCountResponse } from "../../../types/map/customHooks/useWellCount";
import { GetSelectionData } from "../../../types/map/selection/drawToSelect";

import config from "../../../configs/appSettings";

import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";

import { settledPromise } from "../../../utils/api/request";

import { callServiceAPI } from "../../../action/callServiceAPI";
import { getGeometryFromPolygonArray } from "../../../data/map/mapUtils";
import useSearchRequest from "../../common/useSearchRequest";
import { useGridColumn } from "../../grid";
import useUwiFileUpload from "../../search/useUwiFileUpload";

const useSelectionData = () => {
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { getRequiredColumns } = useGridColumn();
  const { buildSearchRequestByParam } = useSearchRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<WellData[]>([]);
  const [error, setError] = useState<unknown>(null);
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const getSelectionData = async ({
    searchCriteria,
    polygonSelect,
    viewport,
  }: GetSelectionData) => {
    setIsLoading(true);
    setError(null);

    const productionWellsUrl = `${config.endpoints.wellService}api/wells/search/`;
    const totalCountUrl = `${config.endpoints.wellService}api/wells/count`;

    const { currentBounds, searchedUWIs, filters, fileId } = searchCriteria;

    //shapeId is not sent in payload, since shapeId and geometry
    //are OR-ed together, which will result always selecting all wells
    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons: polygonSelect,
      currentBounds,
      fileId,
      shapeId: "",
      searchedUWIs,
      filters,
    };

    let searchRequestBody = buildSearchRequestByParam(
      requestBodySearchCriteria,
      viewport
    );

    //make sure that there is geometry on body
    if (
      searchRequestBody &&
      polygonSelect.length &&
      !("geometry" in searchRequestBody)
    ) {
      const geo = getGeometryFromPolygonArray(polygonSelect, viewport);

      if (geo) {
        searchRequestBody = {
          ...searchRequestBody,
          geometry: geo,
        } as SearchRequestWithShapes<BaseSearchRequestNoPaginationPayload>;
      }
    }

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: 0,
      pageLimit: 1,
      columns: getRequiredColumns([]),
    };

    try {
      // Need to get totalCount first for query

      const initialResponse = await callServiceAPI<GetWellCountResponse>(
        totalCountUrl,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        isFromUploadedUWIFile
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const totalCount = initialResponse.data.totalCount;
      const totalIterations = Math.ceil(totalCount / 10000);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((num, i) => {
          return axios.post<WellDataResponse>(
            productionWellsUrl,
            {
              ...body,
              offset: i * 10000,
              pageLimit: 10000,
            },
            {
              ignoreResponseInterceptor: true,
            }
          );
        });

        settledPromise(responses, (values) => {
          const gridDatas = values.flatMap((response) => response.data.wells);
          setData(gridDatas);
          setIsLoading(false);
        });
      } else {
        setData([]);
        setIsLoading(false);
      }
    } catch (error) {
      console.debug("getSelectionData error", error);
      setError(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    getSelectionData,
  };
};

export default useSelectionData;
