import { FC, useEffect } from "react";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";

import classNames from "classnames";

import { ActionButtonsProps } from "../../../types/panels/savedSearchPanel/component";

import useDeleteSavedSearch from "../../../customHooks/savedSearch/useDeleteSavedSearch";

import Loading from "../../common/Loading";

const ActionButtons: FC<ActionButtonsProps> = ({
  id,
  itemState,
  disabled,
  handleDeleteClick,
  handleCancelClick,
  deletingCallback,
  deletedCallback,
}) => {
  const {
    isLoading: deleteSavedSearchLoading,
    data: deletedSavedId,
    error: deleteSavedError,
    deleteSavedSearch,
  } = useDeleteSavedSearch();

  useEffect(() => {
    if (deleteSavedSearchLoading || !deletedSavedId || deleteSavedError) return;
    deletedCallback(deletedSavedId);
  }, [
    deleteSavedSearchLoading,
    deletedSavedId,
    deleteSavedError,
    deletedCallback,
  ]);

  const handleDeleteConfirmClick = () => {
    deletingCallback(id);
    deleteSavedSearch(id);
  };

  return (
    <>
      {!itemState?.onDeleteMode && !itemState?.isDeleting && (
        <IconButton
          edge="end"
          aria-label="delete"
          disabled={disabled}
          onClick={() => handleDeleteClick(id)}
        >
          <DeleteIcon />
        </IconButton>
      )}
      {itemState?.isDeleting && <Loading size={25} />}
      <div
        className={classNames("saved-search-confirm-container", {
          hide: !itemState?.onDeleteMode,
        })}
      >
        <Button variant="text" onClick={() => handleCancelClick(id)}>
          CANCEL
        </Button>
        <Divider orientation="vertical" variant="middle" />
        <Button variant="text" onClick={handleDeleteConfirmClick}>
          CONFIRM
        </Button>
      </div>
    </>
  );
};

export default ActionButtons;
