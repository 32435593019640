import { FC, SVGProps } from "react";

interface DashboardChartsIconProp extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const DashboardChartsIcon: FC<DashboardChartsIconProp> = ({
  width = "25",
  height = "25",
  fill = "none",
  style = {},
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox="0 0 22 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.67497 11C4.67497 11.4557 4.30566 11.825 3.85002 11.825C3.39438 11.825 3.02502 11.4556 3.02502 11C3.02502 6.5955 6.59552 3.025 11 3.025C13.8699 3.025 16.385 4.5415 17.7886 6.81292C18.0281 7.20058 17.9081 7.70894 17.5205 7.94844C17.1329 8.18795 16.6245 8.06796 16.385 7.6803C15.2694 5.87501 13.2745 4.675 11 4.675C7.50676 4.67504 4.67504 7.50676 4.67497 11ZM18.1651 9.86938C18.4929 9.89485 18.7568 10.1487 18.7949 10.4752C18.8329 10.8018 18.6345 11.1096 18.3214 11.2097L18.0262 11.3038C17.8382 11.3636 17.5687 11.4492 17.243 11.5522C16.5917 11.758 15.7151 12.0336 14.8147 12.3119C13.9148 12.5902 12.9883 12.872 12.238 13.0903C11.8631 13.1993 11.5288 13.2935 11.2621 13.3636C11.0139 13.4289 10.7765 13.4866 10.6251 13.5043C9.49368 13.6362 8.46953 12.8259 8.33761 11.6945C8.20567 10.5631 9.01593 9.53893 10.1474 9.40703C10.2988 9.38937 10.5432 9.39093 10.7996 9.39731C11.0753 9.40423 11.4224 9.41893 11.8121 9.43884C12.5925 9.47859 13.559 9.53973 14.4988 9.60347C15.4392 9.66722 16.3557 9.73372 17.0369 9.78422C17.3775 9.80947 17.6594 9.83074 17.8563 9.84576L18.1651 9.86938ZM18.7782 18.7782C14.4824 23.0739 7.51756 23.0739 3.22183 18.7782C-1.07394 14.4824 -1.07391 7.51755 3.22183 3.22182C7.51759 -1.07395 14.4824 -1.07395 18.7782 3.22182C23.0739 7.51755 23.0739 14.4824 18.7782 18.7782ZM4.38855 4.38855C0.737166 8.03994 0.73715 13.96 4.38855 17.6114C8.03994 21.2628 13.9601 21.2628 17.6114 17.6114C21.2629 13.96 21.2628 8.03994 17.6114 4.38855C13.96 0.737148 8.03995 0.737148 4.38855 4.38855Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default DashboardChartsIcon;
