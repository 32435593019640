import { useCallback, useMemo } from "react";

import {
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { Accordion as CLAccordion } from "component-library";

import config from "../../configs/appSettings";

import {
  LAND_GRIDS,
  LAYER_NAME_TO_LABEL_MAPPER,
  MAP_SEARCH_PIPELINES,
} from "../../constants/constants";
import { CULTURE_LAYERS } from "../../constants/map/mapSettings";

import useMapStore from "../../store/map/mapStore";

import CustomCheckbox from "../common/CustomCheckbox";
import TooltipInfoIcon from "../common/icons/TooltipInfoIcon";
import CarbonStorageAccordion from "./CarbonStorageAccordion";
import { CustomTooltip } from "./CustomTooltip";
import GeoEdgeLayerAccordian from "./GeoEdgeLayerAccordian";
import StratigraphicLayerAccordion from "./StratigraphicLayerAccordion";

const CultureLayersAccordion = () => {
  const layers = useMapStore((state) => state.layers);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const toggleLayer = useMapStore((state) => state.toggleLayer);

  const cultureLayers = useMemo(
    () => layers.filter((layer) => CULTURE_LAYERS.includes(layer.name)),
    [layers]
  );

  const isLayerDisabled = useCallback(
    (layerName: string) =>
      [MAP_SEARCH_PIPELINES, LAND_GRIDS].includes(layerName) &&
      currentZoom < 12,
    [currentZoom]
  );

  const isLayerHidden = useCallback(
    (layerName: string) =>
      layerName === MAP_SEARCH_PIPELINES && !config.isMapSearchPipelineEnabled,
    []
  );

  const handleChange = (layerName: string) => {
    toggleLayer(layerName);
  };

  return (
    <CLAccordion title="Culture Layers" className="cl-custom-accordion">
      <FormGroup>
        {cultureLayers.map(
          (layer) =>
            !isLayerHidden(layer.name) && (
              <FormControlLabel
                className="mapsetting-label"
                disabled={
                  layer.isDisabled &&
                  !!layer?.minZoom &&
                  currentZoom < layer?.minZoom
                }
                key={layer.name}
                control={
                  <CustomCheckbox
                    checked={layer.isSelected}
                    onChange={() => handleChange(layer.name)}
                  />
                }
                label={
                  <div className="layer-label-with-icon">
                    <Tooltip title={LAYER_NAME_TO_LABEL_MAPPER[layer.name]}>
                      <Typography className="layerCheckboxlbl">
                        {LAYER_NAME_TO_LABEL_MAPPER[layer.name]}
                      </Typography>
                    </Tooltip>
                    {!isLayerHidden(layer.name) &&
                      isLayerDisabled(layer.name) && (
                        <CustomTooltip
                          title="Min zoom 12"
                          placement="left"
                          arrow
                        >
                          <span className="tooltip-info">
                            <TooltipInfoIcon />
                          </span>
                        </CustomTooltip>
                      )}
                  </div>
                }
              />
            )
        )}
      </FormGroup>
      {config.isStratigraphicEnabled && <StratigraphicLayerAccordion />}

      {config.isCarbonStorageEnabled && <CarbonStorageAccordion />}

      {/* Hidden for stage/prod while Isopach Layers are not yet available */}
      {config.isGeoEdgesEnabled && <GeoEdgeLayerAccordian />}
    </CLAccordion>
  );
};

export default CultureLayersAccordion;
