import { useEffect, useState } from "react";

import { AxiosError, AxiosResponse } from "axios";

import { APIErrorResponse, SearchRequestPayload } from "../types/common/api";
import {
  BaseSearchCriteria,
  WellCountDataPayload,
} from "../types/common/search";
import { GetWellCountDataResponse } from "../types/common/useSearchData";

import config from "../configs/appSettings";

import useUWISearchStore from "../store/search/uwiSearch/searchUWIStore";

import { callServiceAPI } from "../action/callServiceAPI";
import useSearchRequest from "./common/useSearchRequest";
import useUwiFileUpload from "./search/useUwiFileUpload";

const useWellCountData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const hasErrorUploadingUWI = useUWISearchStore(
    (state) => state.hasErrorUploadingUWI
  );
  const [dataPromise, setDataPromise] =
    useState<
      Promise<
        | AxiosError<APIErrorResponse, any>
        | AxiosResponse<GetWellCountDataResponse, any>
        | undefined
      >
    >();
  const [data, setData] = useState(0);
  const [error, setError] = useState(null);
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { buildSearchRequestByParam } = useSearchRequest();

  const awaitDataPromise = async (
    dataPromise: Promise<
      | AxiosError<APIErrorResponse, any>
      | AxiosResponse<GetWellCountDataResponse, any>
      | undefined
    >
  ) => {
    const response = (await dataPromise) as AxiosResponse<
      GetWellCountDataResponse,
      any
    >;

    if (response && response.data) {
      setData(response.data.totalCount);
    } else {
      setData(0);
    }
  };

  useEffect(() => {
    if (dataPromise) {
      awaitDataPromise(dataPromise);
    } else {
      setData(0);
    }
  }, [dataPromise]);

  let debouncedGetExpectedWellCountTimer: NodeJS.Timeout;
  const getExpectedWellCount = async ({
    drawnPolygons = [],
    bounds = [],
    searchedUWIs = [],
    shapeId = "",
    fileId = "",
    fetchedSavedSearchTrigger = false,
  }: WellCountDataPayload) => {
    clearTimeout(debouncedGetExpectedWellCountTimer);

    debouncedGetExpectedWellCountTimer = setTimeout(() => {
      setIsLoading(true);
      setData(0);
      setError(null);

      // Update grid total count only after loading a saved search
      if (fetchedSavedSearchTrigger) {
        setData(0);
        setIsLoading(false);
        return;
      }

      if (hasErrorUploadingUWI) return;

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds: bounds,
        fileId,
        searchedUWIs,
      };

      const searchRequestBody = buildSearchRequestByParam(
        requestBodySearchCriteria
      );

      const body: SearchRequestPayload = {
        ...searchRequestBody,
        offset: 0,
        pageLimit: 1,
        columns: ["ParentWellID", "UWI"],
      };

      try {
        if (
          !drawnPolygons.length &&
          !shapeId &&
          !bounds.length &&
          !fileId &&
          !searchedUWIs.length
        ) {
          setData(0);
          setIsLoading(false);
          return;
        }
        const totalCountUrl = `${config.endpoints.wellService}api/wells/count`;

        const response = callServiceAPI<GetWellCountDataResponse>(
          totalCountUrl,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          !!fileId
        );

        setDataPromise(response);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }, 100);
  };

  return {
    isLoading,
    data,
    error,
    getExpectedWellCount,
  };
};

export default useWellCountData;
