import { useCallback, useMemo } from "react";

import { cloneDeep } from "lodash";

import {
  SavedChartData,
  SavedScatterPlotChartData,
  SavedSearchData,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import { CHART_TYPES } from "../../../constants/charts/charts";
import { CREATE_SAVED_SEARCH } from "../../../constants/panels/savedSearchPanel/hooks";
import { ATTRIBUTE_ALPHA_ASC } from "../../../constants/panels/searchPanel/queryBuilder/sortAttributes";
import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";

import useChartStore from "../../../store/chart/chartStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";

import { defaultFilterModel, defaultSortModel } from "../../../utils/datagrid";

import useSearchData from "./useSearchData";

const useSavedSearchData = () => {
  const { searchData } = useSearchData();
  const sortModel = useDataGridStore((state) => state.sortModel);
  const filterModel = useDataGridStore((state) => state.filterModel);
  const columnVisibilityModel = useDataGridStore(
    (state) => state.columnVisibilityModel
  );
  const columnsOrder = useDataGridStore((state) => state.columnsOrder);

  const layers = useMapStore((state) => state.layers);
  const baseMapSelected = useMapStore((state) => state.baseMapSelected);

  const chartData = useChartStore((state) => state.chartData);
  const dashboardChartData = useChartStore((state) => state.dashboardChartData);

  const chartFullScreenLayout = useModularityStore(
    (state) => state.chartFullScreenLayout
  );
  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );
  const modules = useModularityStore((state) => state.modules);

  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const applyWellColorToPermits = useMapSettingsStore(
    (state) => state.applyWellColorToPermits
  );
  const mapOverlayOpened = useMapSettingsStore(
    (state) => state.mapOverlayOpened
  );
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );
  const stratModelsInfo = useMapSettingsStore((state) => state.stratModelsInfo);

  const currentQBInfo = useQueryBuilderStore((state) => state.currentQBInfo);

  const modulesConfig = useCallback(() => {
    if (!chartData.length) return {};

    // only save chart configs
    const copiedChartData: (SavedChartData | SavedScatterPlotChartData)[] =
      cloneDeep(chartData);
    const mappedChartData = copiedChartData.map((data) => {
      data.chartData = [];
      data.chartRawData = [];
      if (
        data.objectType === CHART_TYPES.PRODUCTION_PLOT ||
        data.objectType === CHART_TYPES.TYPE_CURVE
      ) {
        data.chartDisplayedDataFields = data.chartDisplayedDataFields.map(
          ({ id, name, accumMethod }) => ({ id, name, accumMethod })
        );
      } else if (data.objectType === CHART_TYPES.SCATTER_PLOT) {
        data.chartYAxisDataFields = data.chartYAxisDataFields.map(
          ({ id, name, axis }) => ({ id, name, axis })
        );
        if (data.chartXAxisDataFields) {
          data.chartXAxisDataFields = (({ id, name }) => ({ id, name }))(
            data.chartXAxisDataFields
          );
        }

        if (data.chartColorBy) {
          data.chartColorBy = (({ toggle, attributeKey }) => ({
            toggle,
            attributeKey,
          }))(data.chartColorBy);
        }
      }

      const { objectType, ...modifiedData } = data;

      return modifiedData;
    });

    return {
      modulesConfiguration: {
        layout: {
          default: chartDefaultLayout,
          fullScreen: chartFullScreenLayout,
        },
        modules,
        chartData: mappedChartData,
      },
    };
  }, [chartData, chartDefaultLayout, chartFullScreenLayout, modules]);

  const dashboardChartConfig = useCallback(() => {
    if (!dashboardChartData.length) return {};

    const copiedDashboardChartData = cloneDeep(dashboardChartData);
    const mappedDashboardChartData = copiedDashboardChartData.map(
      ({ chartId, chartType, groupBy }) => ({ chartId, chartType, groupBy })
    );
    return {
      dashboardChartConfiguration: {
        dashboardChartData: mappedDashboardChartData,
      },
    };
  }, [dashboardChartData]);

  const mapConfig = useMemo(() => {
    if (!mapOverlayOpened.length) return {};
    return { mapOverlayOpened };
  }, [mapOverlayOpened]);

  const gridConfig = useCallback(
    (action: string, hasLoadedAndUpdatedSearch: boolean) => {
      if (action !== CREATE_SAVED_SEARCH || !hasLoadedAndUpdatedSearch) {
        return {};
      }

      // Do not include the filter and sort when
      // loaded a saved search and updated values on the other tab panel
      // then clicking save
      return { sortModel: defaultSortModel, filterModel: defaultFilterModel };
    },
    []
  );

  const savedSearchData = useCallback(
    ({
      action = CREATE_SAVED_SEARCH,
      searchType = SEARCH_TYPES.ATTRIBUTE_SEARCH,
      hasLoadedAndUpdatedSearch = false,
    }) => {
      const { searchState, searchTypeToBeSaved } = searchData(searchType);

      const savedSearchData: SavedSearchData = {
        gridConfiguration: {
          columnsModel: columnVisibilityModel,
          columnsOrder,
          sortModel,
          filterModel,
          ...gridConfig(action, hasLoadedAndUpdatedSearch),
        },
        mapConfiguration: {
          layers: layers.map(({ name, isSelected }) => ({ name, isSelected })),
          baseMap: baseMapSelected,
          layerStyles,
          applyWellColorToPermits,
          carbonStorageInfo,
          stratModelsInfo,
          ...mapConfig,
        },
        ...searchState,
        ...modulesConfig(),
        ...dashboardChartConfig(),
      };

      return {
        searchTypeToBeSaved,
        searchData: savedSearchData,
      };
    },
    [
      layers,
      baseMapSelected,
      layerStyles,
      applyWellColorToPermits,
      columnVisibilityModel,
      columnsOrder,
      sortModel,
      filterModel,
      mapConfig,
      gridConfig,
      searchData,
      modulesConfig,
      dashboardChartConfig,
    ]
  );

  const sortPerAttribute = useMemo(() => {
    return Object.fromEntries(
      Object.entries(currentQBInfo)
        .filter((val) => val[1].sortKey !== undefined)
        .map(([att, info]) => [att, info.sortKey ?? ATTRIBUTE_ALPHA_ASC])
    );
  }, [currentQBInfo]);

  return { savedSearchData };
};

export default useSavedSearchData;
