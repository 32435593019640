import { useCallback, useEffect, useMemo, useState } from "react";

import classNames from "classnames";

import config from "../../../configs/appSettings";

import { MAP_SEARCH_PIPELINES } from "../../../constants/constants";
import {
  CARBON_LAYER_BASINS,
  CARBON_STORAGE_LABEL,
} from "../../../constants/map/carbonStorage";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import { clone } from "../../../utils";
import CarbonStorageLegend from "./CarbonStorageLegend";
import MapSearchPipelinesLegend from "./MapSearchPipelinesLegend";

const LayerInfoOverlay = () => {
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const layers = useMapStore((state) => state.layers);
  const currentZoom = useMapStore((state) => state.currentZoom);

  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );

  const [layerLegend, setLayerLegend] = useState<string[]>([]);

  const isLayerOn = useCallback(
    (layerName: string) => {
      return !!layers.find((layer) => layer.name === layerName)?.isSelected;
    },
    [layers]
  );

  const isMapSearchPipelinesOn = useMemo(
    () =>
      config.isMapSearchPipelineEnabled &&
      isLayerOn(MAP_SEARCH_PIPELINES) &&
      currentZoom >= 12,
    [isLayerOn, currentZoom]
  );

  const isCarbonStorageBasinOn = useMemo(
    () =>
      config.isCarbonStorageEnabled &&
      carbonStorageInfo.isSelected &&
      carbonStorageInfo.basins[CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]
        .isSelected,
    [carbonStorageInfo]
  );

  const getLeftPosition = useMemo(() => {
    let value = 10;
    if (isOpenAppLevelNav && isOpenLeftSidePanel) {
      value = 474;
    } else if (isOpenAppLevelNav) {
      value = 74;
    }

    return value;
  }, [isOpenAppLevelNav, isOpenLeftSidePanel]);

  const getBottomPosition = useMemo(() => {
    let bottom = `calc(${gridHeight} + 30px)`;

    if (!showGrid && !showGridHeader) {
      bottom = "30px";
    } else if (!showGrid && showGridHeader) {
      bottom = "85px";
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader]);

  const getLegendComponent = useCallback((layerName: string) => {
    if (layerName === MAP_SEARCH_PIPELINES) {
      return <MapSearchPipelinesLegend key={layerName} />;
    } else if (layerName === CARBON_STORAGE_LABEL) {
      return <CarbonStorageLegend key={layerName} />;
    } else {
      return null;
    }
  }, []);

  const getUpdatedLegendList = useCallback(
    (layerName: string, layerFlag: boolean) => {
      let legends: string[] = clone(layerLegend);
      if (layerFlag) {
        legends.push(layerName);
      } else {
        legends = legends.filter((name) => name !== layerName);
      }
      return legends;
    },
    [layerLegend]
  );

  useEffect(() => {
    const legends = getUpdatedLegendList(
      MAP_SEARCH_PIPELINES,
      isMapSearchPipelinesOn
    );
    setLayerLegend(legends);
  }, [isMapSearchPipelinesOn]);

  useEffect(() => {
    const legends = getUpdatedLegendList(
      CARBON_STORAGE_LABEL,
      isCarbonStorageBasinOn
    );
    setLayerLegend(legends);
  }, [isCarbonStorageBasinOn]);

  return (
    <div
      className={classNames("layer-info-overlay", {
        hide: !isMapSearchPipelinesOn && !isCarbonStorageBasinOn,
      })}
      style={{ left: getLeftPosition, bottom: getBottomPosition }}
    >
      {layerLegend.map((layerName) => getLegendComponent(layerName))}
    </div>
  );
};

export default LayerInfoOverlay;
