import { useCallback } from "react";

import { Coordinate } from "ol/coordinate";
import { easeIn } from "ol/easing.js";
import { Group as LayerGroup, Tile as TileLayer } from "ol/layer";
import { XYZ } from "ol/source";

import axios from "axios";

import { SearchRequestPayload } from "../../types/common/api";
import { AttributeBound } from "../../types/common/attributes";
import { BaseSearchCriteria } from "../../types/common/search";

import config from "../../configs/appSettings";

import {
  DYNAMIC_PERMIT_SPOTS,
  GEOPOINT,
  PERMIT_SPOTS,
  SURFACE_GEO_POINT,
  VECTOR_TYPE_WELLS,
} from "../../constants/constants";
import {
  GEO_COLUMN_PERMITS,
  GEO_COLUMN_TO_LAYER_MAPPER,
} from "../../constants/map/geoColumns";
import {
  DARK_GRAY,
  HYBRID,
  IMAGERY_WITH_LABELS,
  LIGHT_GREY_CANVAS,
  NAT_GEO,
  OCEANS,
  STREETS,
  TOPOGRAPHIC,
  baseMapLayer,
} from "../../constants/map/layers";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapStore from "../../store/map/mapStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { createRecordTypeBounds } from "../../utils/common/boundsData";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUwiFileUpload from "./../search/useUwiFileUpload";

const vectorTilesURL = `${config.endpoints.wellService}api/wells/vector-tiles`;

const useSagaMap = () => {
  const map = useMapStore((state) => state.map);
  const baseMapSelected = useMapStore((state) => state.baseMapSelected);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const setCurrentZoom = useMapStore((state) => state.setCurrentZoom);

  const DVTQueryLoading = useMapStore((state) => state.DVTQueryLoading);
  const updateDVTQueryLoading = useMapStore(
    (state) => state.updateDVTQueryLoading
  );
  const DVTQuery = useMapStore((state) => state.DVTQuery);
  const updateDVTQuery = useMapStore((state) => state.updateDVTQuery);
  const DVTQueryError = useMapStore((state) => state.DVTQueryError);
  const updateDVTQueryError = useMapStore((state) => state.updateDVTQueryError);

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );

  // Search Panel
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);

  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { buildSearchRequestByParam } = useSearchRequest();

  const getDVTQuery = useCallback(
    async ({ id, geoColumn }: { id: string; geoColumn: string }) => {
      updateDVTQueryLoading({ [id]: true });
      try {
        let query = "";
        let hasCount = true;
        const {
          currentBounds,
          drawnPolygons,
          searchedUWIs,
          filters,
          fileId,
          shapeId,
        } = searchCriteria;

        if (
          currentBounds?.length ||
          drawnPolygons?.length ||
          searchedUWIs?.length ||
          fileId !== "" ||
          shapeId !== ""
        ) {
          const requestBodySearchCriteria: BaseSearchCriteria = {
            drawnPolygons,
            shapeId,
            currentBounds,
            fileId,
            searchedUWIs,
            filters,
          };

          const searchRequestBody = buildSearchRequestByParam(
            requestBodySearchCriteria
          );

          const body: SearchRequestPayload = {
            ...searchRequestBody,
            offset: 0,
            pageLimit: 1,
          };

          if (id === PERMIT_SPOTS || id === DYNAMIC_PERMIT_SPOTS) {
            body.columns = ["WellHeaderPermitKey"];
          } else {
            body.columns = ["WellID"];
          }

          try {
            // Check if the query has more than 1 results first
            const totalCountUrl = `${config.endpoints.wellService}api/wells/count`;
            body.notNullColumns = [geoColumn];

            const isWellsVector = VECTOR_TYPE_WELLS.includes(id);

            //if layer is for Permits, remove Wells in Record Type bounds, if exists
            //if layer is for Wells, remove permits in Recort Type bounds, if exists
            if (body.bounds) {
              body.bounds = body.bounds.map((bound: AttributeBound) => {
                if (
                  bound.bound === "RecordType" &&
                  bound.operations[0].values.length === 2
                ) {
                  return {
                    ...bound,
                    ...createRecordTypeBounds(
                      isWellsVector ? RECORD_TYPES.WELL : RECORD_TYPES.PERMIT
                    ),
                  };
                } else {
                  return bound;
                }
              });
            }

            const countRes: any = await callServiceAPI(
              totalCountUrl,
              body,
              getUWIFileIdWithRetry,
              uploadedUWIFile,
              isFromUploadedUWIFile
            );

            if (countRes?.data.totalCount) {
              const queryBody: any = {};
              queryBody.searchRequest = body;
              if (id === PERMIT_SPOTS || id === DYNAMIC_PERMIT_SPOTS) {
                queryBody.layer = GEO_COLUMN_PERMITS;
              } else {
                queryBody.layer = GEO_COLUMN_TO_LAYER_MAPPER[geoColumn];
              }

              const res = await axios.post(vectorTilesURL, queryBody);
              query = res.data.query;
            } else {
              hasCount = false;
              query = `SELECT ParentWellID, UWI, ${SURFACE_GEO_POINT} AS ${GEOPOINT} from \`${config.BQ_TABLE}.Saga.WellPerformanceHeader\` WHERE REGEXP_CONTAINS(UWI, '^42053000080000');`;
            }
          } catch (error) {
            console.error("processDynamicVectorTile SQL generate error", error);
          }

          updateDVTQuery({
            [id]: {
              query,
              hasCount,
              geoColumn,
            },
          });
          updateDVTQueryLoading({ [id]: false });

          return {
            query,
            hasCount,
            geoColumn,
          };
        }
      } catch (error) {
        console.error("getDVTQuery error", error);
        updateDVTQuery({});
        updateDVTQueryError(error);
        updateDVTQueryLoading({ [id]: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchCriteria]
  );

  const incrementZoomLevel = () => {
    if (!map) {
      return;
    }
    const zoom = map.getView().getZoom();

    map.getView().animate({
      zoom: zoom ? zoom + 1 : zoom,
      duration: 250,
    });
    if (zoom) setCurrentZoom(zoom);
  };

  const decrementZoomLevel = () => {
    if (!map) {
      return;
    }
    const zoom = map.getView().getZoom();

    map.getView().animate({
      zoom: zoom ? zoom - 1 : zoom,
      duration: 250,
    });
    if (zoom) setCurrentZoom(zoom);
  };

  const updateBaseMap = (selectedBaseMap = "Oceans") => {
    if (!map) {
      return;
    }

    let baseMap;

    const removeOceansLabel = () => {
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("title") === "OceansLabel")
        .forEach((layer) => {
          if (layer) {
            map.removeLayer(layer);
          }
        });
    };

    if (selectedBaseMap === TOPOGRAPHIC.id) {
      removeOceansLabel();
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.topographicBase,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          constrainResolution: true,
          title: "Topographic",
          isBaseMap: true,
        },
      });
    } else if (selectedBaseMap === OCEANS.id) {
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.OceanBaseMap,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          title: "Oceans",
          constrainResolution: true,
          isBaseMap: true,
        },
      });
      const OceansLabels = new TileLayer({
        source: new XYZ({
          url: config.endpoints.OceansBaseMapLabel,
        }),
        visible: true,
        zIndex: 2000,
        properties: {
          isBaseMap: true,
          title: "OceansLabel",
          constrainResolution: true,
        },
      });
      map.addLayer(OceansLabels);
    } else if (selectedBaseMap === LIGHT_GREY_CANVAS.id) {
      removeOceansLabel();
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.lightGrayCanvasBaseMap,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          constrainResolution: true,
          title: "LightGreyCanvas",
          isBaseMap: true,
        },
      });
    } else if (selectedBaseMap === IMAGERY_WITH_LABELS.id) {
      removeOceansLabel();
      baseMap = new LayerGroup({
        layers: [
          new TileLayer({
            source: new XYZ({
              url: config.endpoints.worldImageryBaseMap,
            }),
            visible: true,
            zIndex: 0,
            properties: {
              constrainResolution: true,
              title: "ImageryLabel",
              isBaseMap: true,
            },
          }),
          new TileLayer({
            source: new XYZ({
              url: config.endpoints.worldBoundaries,
            }),
            properties: {
              isBaseMap: true,
            },
          }),
        ],
        properties: {
          isBaseMap: true,
        },
      });
    }
    //Streets
    else if (selectedBaseMap === STREETS.id) {
      removeOceansLabel();
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.streetsCanvasBaseMap,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          constrainResolution: true,
          title: "Streets",
          isBaseMap: true,
        },
      });
    }
    //Hybrid
    else if (selectedBaseMap === HYBRID.id) {
      removeOceansLabel();
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.hybridBaseMap,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          constrainResolution: true,
          title: "Hybrid",
          isBaseMap: true,
        },
      });
      //DarkGray
    } else if (selectedBaseMap === DARK_GRAY.id) {
      removeOceansLabel();
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.darkGrayCanvasBaseMap,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          constrainResolution: true,
          title: "DarkGray",
          isBaseMap: true,
        },
      });
      //NatGeo
    } else if (selectedBaseMap === NAT_GEO.id) {
      removeOceansLabel();
      baseMap = new TileLayer({
        source: new XYZ({
          url: config.endpoints.nationalGeographicBaseMap,
        }),
        visible: true,
        zIndex: 0,
        properties: {
          constrainResolution: true,
          title: "NatGeo",
          isBaseMap: true,
        },
      });
    }

    if (baseMap) map.getLayers().setAt(0, baseMap);
  };

  const setZoomLevel = (zoom: number) => {
    if (!map) {
      return;
    }

    const currentZoom = map.getView().getZoom();

    if (currentZoom) {
      map.getView().animate({
        zoom,
        duration: 500 * Math.abs(currentZoom - zoom),
        easing: easeIn,
      });
      setCurrentZoom(currentZoom);
    }
  };

  const setCenter = (center: Coordinate | undefined) => {
    if (!map) {
      return;
    }

    map.getView().setCenter(center);
  };

  const resetMapViewBaseMap = () => {
    // Reset zoom and center
    const defaultZoom = config.defaultViewSettings.zoom;
    const defaultCenter = config.defaultViewSettings.center;
    const currentCenter = map ? map.getView().getCenter() : undefined;

    let duration = 0;
    if (currentZoom - defaultZoom !== 0) {
      duration = 500 * Math.abs(currentZoom - defaultZoom);
    } else if (
      currentCenter &&
      (currentCenter[0] - defaultCenter[0] !== 0 ||
        currentCenter[1] - defaultCenter[1] !== 0)
    ) {
      duration = 1000;
    }

    map?.getView().animate({
      zoom: defaultZoom,
      center: defaultCenter,
      duration,
      easing: easeIn,
    });

    // Reset base map to default
    const defaultBaseMap = baseMapLayer[6].id;
    if (baseMapSelected !== defaultBaseMap) {
      updateBaseMap(defaultBaseMap);
    }
  };

  return {
    map,
    incrementZoomLevel,
    decrementZoomLevel,
    updateBaseMap,
    setZoomLevel,
    setCenter,

    DVTQueryLoading,
    DVTQuery,
    DVTQueryError,
    getDVTQuery,

    resetMapViewBaseMap,
  };
};

export default useSagaMap;
