import {
  GetAllSavedSearchesResponse,
  SavedSearchInfo,
} from "../../types/panels/savedSearchPanel/savedSearchData";

import useStore from "../../store/useStore";

import { formatCurrentDateTime } from "../../utils";
import useSavedSearchData from "../savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";
import useSearchPanel from "../search/useSearchPanel";
import useSearchPanelActions from "../search/useSearchPanelActions";

const useSaveExportSearch = () => {
  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );

  const { searchTypeByActiveTab: searchType, searchTitle } = useSearchPanel();
  const { saveCallback } = useSearchPanelActions();

  const isFilterIncluded =
    !selectedSavedSearchData?.searchType || searchTitle !== "SEARCH";

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchError,
    createSavedSearch,
  } = useSavedSearchData();

  const savedSearchName = searchType + " " + formatCurrentDateTime();

  const getExportSaveSearch = (
    currentSavedSearch?: GetAllSavedSearchesResponse,
    loadedSearchName?: string
  ) => {
    if (!loadedSearchName) {
      createSavedSearch(
        savedSearchName,
        searchType,
        isFilterIncluded,
        true,
        saveCallback
      );
    } else {
      let modifyCurrentSearchName: string;
      let checkSearchIteration: string[];
      let setSearchIteration = 0;

      if (loadedSearchName.includes("_")) {
        checkSearchIteration = loadedSearchName.split("_");
        currentSavedSearch?.forEach((search: SavedSearchInfo) => {
          if (search.searchName.includes(checkSearchIteration[0])) {
            setSearchIteration = setSearchIteration + 1;
          }
        });
        modifyCurrentSearchName =
          checkSearchIteration[0] + "_" + setSearchIteration;
      } else {
        currentSavedSearch?.forEach((search: SavedSearchInfo) => {
          if (search.searchName.includes(loadedSearchName)) {
            setSearchIteration = setSearchIteration + 1;
          }
        });
        modifyCurrentSearchName = loadedSearchName + "_" + setSearchIteration;
      }
      createSavedSearch(
        modifyCurrentSearchName,
        searchType,
        isFilterIncluded,
        true,
        saveCallback
      );
    }
  };
  return {
    getExportSaveSearch,
    savedSearchDataLoading,
    savedSearchData,
    savedSearchError,
  };
};

export default useSaveExportSearch;
