import { StateCreator } from "zustand";

import {
  FileUwiSearchAction,
  FileUwiSearchStore,
  PastedUwiSearchAction,
  PastedUwiSearchStore,
  ResetUwiSearch,
  UWISearchStore,
} from "../../../types/panels/searchPanel/uwiSearch";

const pastedUwiSearch: StateCreator<
  UWISearchStore,
  [],
  [],
  PastedUwiSearchStore & PastedUwiSearchAction
> = (set) => ({
  searchUWIText: "",
  isInvalidUWIText: false,
  uwisToSearch: [],
  updateSearchUWIText: (searchUWIText) => set({ searchUWIText }),
  updateIsInvalidUWIText: (isInvalidUWIText) => set({ isInvalidUWIText }),
  updateUWIsToSearch: (uwisToSearch) => set({ uwisToSearch }),
  loadSavedUWIs: (uwis, isAutoConvertedFileFromText) =>
    set({
      searchUWIText: uwis.join("\n"),
      isInvalidUWIText: false,
      uwisToSearch: isAutoConvertedFileFromText ? [] : uwis,
      isAutoConvertedFileFromText,
    }),
});

const fileUwiSearch: StateCreator<FileUwiSearchStore & FileUwiSearchAction> = (
  set
) => ({
  uploadedUWIFile: null,
  uploadedUwiFileId: "",
  uploadFileProgress: 0,
  fileTimeStamp: null,
  isFromUploadedUWIFile: false,
  hasErrorUploadingUWI: false,
  isLoadingSavedSearchFileUpload: false,
  isLoadingUwiFileUpload: false,
  isFileFormatNotAllowed: false,
  isValidUwiFile: false,
  isValidatingUWITexts: false,
  isAutoConvertedFileFromText: false,
  updateUploadedUWIFile: (uploadedUWIFile) => set({ uploadedUWIFile }),
  updateUploadedUwiFileId: (fileId) => set({ uploadedUwiFileId: fileId }),
  updateUploadFileProgress: (progress) => set({ uploadFileProgress: progress }),
  updateFileTimeStamp: (fileTimeStamp) => set({ fileTimeStamp }),
  updateIsFromUploadedUWI: (isUploaded) =>
    set({ isFromUploadedUWIFile: isUploaded }),
  updateHasErrorUploadingUWI: (hasError) =>
    set({ hasErrorUploadingUWI: hasError }),
  updateIsLoadingSavedSearchFileUpload: (isLoading) =>
    set({ isLoadingSavedSearchFileUpload: isLoading }),
  setIsLoadingUWIFileUpload: (isLoading) =>
    set({ isLoadingUwiFileUpload: isLoading }),
  setIfFileUploadFormatNotAllowed: (isFormatAllowed) =>
    set({ isFileFormatNotAllowed: isFormatAllowed }),
  setIsValidUwiFile: (isValidUwiFile) =>
    set({ isValidUwiFile: isValidUwiFile }),
  setIsValidatingUWITexts: (isValidatingUwiTexts) =>
    set({ isValidatingUWITexts: isValidatingUwiTexts }),
  updateIsAutoConvertedFileFromText: (isAutoConvertedFileFromText) =>
    set({ isAutoConvertedFileFromText }),
});

const resetUwis: StateCreator<UWISearchStore, [], [], ResetUwiSearch> = (
  set
) => ({
  resetUWIsToSearch: () =>
    set({ searchUWIText: "", isInvalidUWIText: false, uwisToSearch: [] }),
  resetUWIUploadStates: () =>
    set({
      uploadedUWIFile: null,
      uploadedUwiFileId: "",
      uploadFileProgress: 0,
      hasErrorUploadingUWI: false,
      isFromUploadedUWIFile: false,
    }),
  resetUWIStates: () =>
    set({
      searchUWIText: "",
      isFromUploadedUWIFile: false,
      isInvalidUWIText: false,
      isValidUwiFile: false, // Todo: update into isInvalidUWIFile for consistency
      uwisToSearch: [],
      uploadedUWIFile: null,
      uploadedUwiFileId: "",
      uploadFileProgress: 0,
      hasErrorUploadingUWI: false,
      isAutoConvertedFileFromText: false,
    }),
});

export { fileUwiSearch, pastedUwiSearch, resetUwis };
