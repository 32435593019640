import { WellData } from "../../../types/common/wells";
import { WellPermitPanelState } from "../../../types/panels/sidebar";
import {
  MapLayer,
  WellPanelInfo,
} from "../../../types/panels/wellPanel/wellPanel";

import { DYNAMIC_WELL_SPOTS, WELL_SPOTS } from "../../../constants/constants";
import { SIDEBAR_MENU_TEXTS } from "../../../constants/panels/menu";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { MAP_LAYER } from "../../../constants/panels/wellPanel/wellInfo";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapHoverStore from "../../../store/map/hover/mapHoverStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import useWellCardState from "../../map/useWellCardState";

const useWellPanelViewAction = () => {
  const mapHoverData = useMapHoverStore((state) => state.mapHoverData);

  const wellInfoList = usePanelsStore((state) => state.wellList);
  const permitsInfoList = usePanelsStore((state) => state.permitsList);
  const updateWellList = usePanelsStore((state) => state.updateWellList);
  const toggleIsWellPanelNavCollapsed = usePanelsStore(
    (state) => state.toggleIsWellPanelNavCollapsed
  );
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateWellPanelPaginationByKey = usePanelsStore(
    (state) => state.updateWellPanelPaginationByKey
  );
  const updateActiveWellPanelByKey = usePanelsStore(
    (state) => state.updateActiveWellPanelByKey
  );
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );
  const toggleAlertMessage = usePanelsStore(
    (state) => state.toggleAlertMessage
  );
  const addSelectedWellCardPWID = usePanelsStore(
    (state) => state.addSelectedWellCardPWID
  );
  const addSelectedWellCardBWID = usePanelsStore(
    (state) => state.addSelectedWellCardBWID
  );
  const addMultipleSelectedWellCardBWID = usePanelsStore(
    (state) => state.addMultipleSelectedWellCardBWID
  );
  const selectedWellCardPWID = usePanelsStore(
    (state) => state.selectedWellCardPWIDs
  );
  const selectedWellCardBWIDs = usePanelsStore(
    (state) => state.selectedWellCardBWIDs
  );

  const { groupedByParentWellID } = useDataGridStore(
    (state) => state.allWellSelectors
  );

  const {
    getGroupedWellID,
    isWellSystemExisting,
    isActivelyViewingWell,
    isActivelyViewingSurfaceWellSpotPanel,
    isSurfaceWellSpotPanelExistingForBottomWellSPot,
    isBottomWellSpotPanelExistingForSurfaceWellSpot,
    isActiveViewingBottomWellSpotPanel,
    shoudDisableOpenWellPanel,
  } = useWellCardState();

  const updateWellSpotSize = (
    layer: MapLayer | undefined,
    wellData: WellData
  ) => {
    if (!layer) {
      return;
    }

    if (
      layer === MAP_LAYER.WELL_SPOTS ||
      layer === MAP_LAYER.DYNAMIC_WELL_SPOTS
    ) {
      if (wellData && !selectedWellCardPWID.includes(wellData.ParentWellID)) {
        addSelectedWellCardPWID(wellData.ParentWellID);

        if (wellData.groupedBWIDs?.length) {
          addMultipleSelectedWellCardBWID(wellData.groupedBWIDs);
        }
      }
    } else if (
      layer === MAP_LAYER.BOTTOM_WELL_SPOTS ||
      layer === MAP_LAYER.DYNAMIC_BOTTOM_WELL_SPOTS ||
      layer === MAP_LAYER.WELL_PATHS ||
      layer === MAP_LAYER.WELL_STICKS
    ) {
      if (
        wellData &&
        !selectedWellCardBWIDs.includes(wellData.BottomWellboreID)
      ) {
        addSelectedWellCardBWID(wellData.BottomWellboreID);

        const surfaceEquivalent = groupedByParentWellID[wellData.ParentWellID];

        if (surfaceEquivalent?.UWI === wellData.UWI) {
          addSelectedWellCardPWID(wellData.ParentWellID);
        }
      }
    }
  };

  const handleOpenWellCard = () => {
    if (
      mapHoverData?.layer.id &&
      getGroupedWellID &&
      !shoudDisableOpenWellPanel
    ) {
      if (isWellSystemExisting) {
        const activeWellInfo = isActivelyViewingWell;
        if (activeWellInfo) {
          updateWellPanelPaginationByKey(
            mapHoverData.layer.id as MapLayer,
            getGroupedWellID,
            activeWellInfo.wellPage
          );
          if (!activeWellInfo.isActive) {
            updateActiveWellPanelByKey(
              mapHoverData.layer.id as MapLayer,
              getGroupedWellID
            );
          }
        }
      } else if (isBottomWellSpotPanelExistingForSurfaceWellSpot) {
        const activeWellInfo = isActiveViewingBottomWellSpotPanel;
        if (activeWellInfo) {
          updateWellPanelPaginationByKey(
            mapHoverData.layer.id as MapLayer,
            getGroupedWellID,
            activeWellInfo.wellPage
          );
          if (!activeWellInfo.isActive) {
            updateActiveWellPanelByKey(
              mapHoverData.layer.id as MapLayer,
              getGroupedWellID
            );
          }
        }
      } else if (isSurfaceWellSpotPanelExistingForBottomWellSPot) {
        const activeWellInfo = isActivelyViewingSurfaceWellSpotPanel;
        if (activeWellInfo) {
          updateWellPanelPaginationByKey(
            mapHoverData.layer.id as MapLayer,
            getGroupedWellID,
            activeWellInfo.wellPage
          );
          if (!activeWellInfo.isActive) {
            updateActiveWellPanelByKey(
              mapHoverData.layer.id as MapLayer,
              getGroupedWellID
            );
          }
        }
      } else {
        const wellsAndPermitPanel: WellPermitPanelState[] = [
          ...wellInfoList,
          ...permitsInfoList,
        ];

        if (wellsAndPermitPanel.length === 10) {
          toggleAlertMessage(true);
          return;
        }
        const wellType = mapHoverData.layer.id as MapLayer;
        const wellData = mapHoverData.info;

        const {
          WellID,
          UWI,
          Country,
          SurfaceUWI,
          BottomWellboreID,
          ParentWellID,
          groupedBWIDs,
          WellHeaderPermitKey,
        } = wellData;

        if (WellID) {
          const wellInfo: WellPanelInfo = {
            bottomWellBoreId: BottomWellboreID,
            parentWellId: ParentWellID,
            groupedWellID: getGroupedWellID,
            wellHeaderPermitKey: WellHeaderPermitKey,
            uwi: UWI ?? "",
            wellId: WellID,
            wellType: wellType,
            activePage: 1,
            createdData: new Date(),
            surfaceUWI: SurfaceUWI ?? "",
            country: Country ?? "",
            recordType: RECORD_TYPES.WELL,
            isActive: true,
            ...([WELL_SPOTS, DYNAMIC_WELL_SPOTS].includes(wellType) && {
              groupedBWIDs: groupedBWIDs,
            }),
          };

          updateWellSpotSize(wellInfo.wellType, wellData);
          updateWellList(wellInfo);
        }
      }

      toggleIsWellPanelNavCollapsed(true);
      toggleLeftSidePanel(true);
      updateActivePanel(SIDEBAR_MENU_TEXTS.WELL_PANEL);
      updateActivePermitPanel(false);
    }
  };

  return { handleOpenWellCard };
};

export default useWellPanelViewAction;
