import { useCallback, useState } from "react";

import axios from "axios";
import { Data } from "plotly.js";

import {
  ChartAxisDataFields,
  ChartAxisType,
  IScatterPlotChartData,
  ITraceData,
  ScatterPlotChartResponseData,
  ScatterPlotWellChartData,
} from "../../types/charts/chartType/chartType";
import { AttributeDataType } from "../../types/common/attributes";

import config from "../../configs/appSettings";

import { DATE } from "../../constants/attributes";
import { CHART_AXIS } from "../../constants/charts/charts";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { settledPromise } from "../../utils/api/request";
import { differenceInMonths } from "../../utils/charts/dateHelper";
import {
  formatDefaultTopColorsToRGB,
  getFieldUnitValue,
} from "../../utils/charts/formatter";
import { convertToMilliseconds } from "../../utils/helper";
import { getMaxValue, getMinValue } from "../../utils/map/wellStyling";

import { callServiceAPI } from "../../action/callServiceAPI";
import { useGridSelectedData } from "../grid/useGridSelectedData";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

const scatterPlotEndpoint = `${config.endpoints.wellService}api/wells/chart/scatter`;
const limit = config.searchPageLimit;
export const useScatterPlotChartData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ScatterPlotWellChartData[] | null>(null);
  const [isError, setIsError] = useState(false);

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const { getUWIFileIdWithRetry } = useUwiFileUpload();

  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const { isSelectedAllGrid } = useGridSelectedData();
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const { buildChartPostRequest } = useChartSearchRequest();

  const formatTrace = (
    formattedTraceName: string,
    formattedUnit: string,
    formattedText: string,
    markerColor: string | number[],
    attributeKey: string,
    showlegend = true,
    legendgroup?: string
  ) => {
    const trace: ITraceData = {
      id: formattedTraceName,
      mode: "markers",
      type: "scattergl",
      unit: formattedUnit,
      hoverlabel: { namelength: -1 },
      hoverinfo: "x+y+text",
      text: formattedText,
      name: formattedTraceName,
      marker: { color: markerColor },
      x: [],
      y: [],
      visible: true,
      showlegend: showlegend,
      attributeId: attributeKey,
      ...(legendgroup && {
        legendgroup: legendgroup,
      }),
    };
    return trace;
  };

  const mapTraceData = (
    trace: ITraceData,
    traceName: string,
    tracePosition?: ChartAxisType
  ) => {
    const obj: Data = {
      name: traceName,
      type: trace.type,
      mode: trace.x.length > 1 ? trace.mode : "markers",
      ...(trace.line && {
        line: trace.line,
      }),
      hoverlabel: trace.hoverlabel,
      hoverinfo: trace.hoverinfo,
      ...(trace.xhoverformat && {
        xhoverformat: trace.xhoverformat,
      }),
      text: trace.text,
      marker: trace.marker,
      x: trace.x,
      y: trace.y,
      visible: trace.visible,
      ...(tracePosition === CHART_AXIS.RIGHT && {
        yaxis: "y2",
      }),
      showlegend: trace.showlegend,
      ...(trace.legendgroup && {
        legendgroup: trace.legendgroup,
      }),
    };

    return obj;
  };

  const createDefaultTrace = (name: string, axis?: ChartAxisType) => {
    return {
      x: [],
      y: [],
      name: name,
      ...(axis === CHART_AXIS.RIGHT && {
        yaxis: "y2",
      }),
      type: "scatter",
    } as Data;
  };

  const createTrace = (
    data: ScatterPlotWellChartData[],
    xAxisField: ChartAxisDataFields | undefined,
    yAxisFields: ChartAxisDataFields[]
  ) => {
    const chartTrace: ITraceData[] = [];

    if (data.length && xAxisField && yAxisFields.length) {
      yAxisFields.forEach((field, index) => {
        const fieldTrace = formatTrace(
          field.displayName,
          getFieldUnitValue(field.name),
          "",
          formatDefaultTopColorsToRGB()[index],
          field.name
        );
        chartTrace.push(fieldTrace);
      });

      data.forEach((obj) => {
        const objFields = obj.fields;
        //check if xaxis exists
        const xAxisFieldValue = objFields.find(
          (data) => data.name === xAxisField.name && data.val
        );

        if (xAxisFieldValue) {
          chartTrace.forEach((trace) => {
            const yAxisFieldValue = objFields.find(
              (data) => data.name === trace.attributeId && data.val
            );

            if (yAxisFieldValue) {
              trace.x.push(xAxisFieldValue?.val);
              trace.y.push(yAxisFieldValue?.val);
            }
          });
        }
      });
    }
    return chartTrace;
  };

  const getChartData = useCallback(
    async (chartData: IScatterPlotChartData) => {
      setIsLoading(true);
      setData(null);

      try {
        setIsError(false);

        const yAxisFields = chartData.chartYAxisDataFields?.map((val) => {
          return { fieldName: val.name };
        });

        const body: any = {
          chartType: "SCATTER_PLOT",
          fields: [
            //x-axis value
            { fieldName: chartData.chartXAxisDataFields?.name },
          ],
        };

        // check if attributes exists before adding y attribute
        yAxisFields.forEach((data) => {
          const yAxisExists = body.fields.filter(
            (field: any) => field.fieldName === data.fieldName
          ).length;

          if (!yAxisExists) {
            body.fields.push({ fieldName: data.fieldName });
          }
        });

        //check if attribute exists on x axis or y axis and if color by is on
        if (
          chartData.chartColorBy?.toggle &&
          !body.fields.filter(
            (field: any) =>
              field.fieldName === chartData.chartColorBy?.attributeKey
          ).length
        ) {
          body.fields.push({ fieldName: chartData.chartColorBy.attributeKey });
        }

        body.searchRequest = buildChartPostRequest(chartData.chartType);
        body.searchRequest = {
          ...body.searchRequest,
          pageLimit: limit,
          ignorePageLimit: false,
          offset: 0,
        };

        //get total count
        const initialResponse: any = await callServiceAPI(
          scatterPlotEndpoint,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          isFromUploadedUWIFile
        );

        if (!initialResponse || !("data" in initialResponse)) {
          setData([]);
          setIsError(true);
          return;
        }

        const totalCount = initialResponse.data.totalCount;
        const totalIterations = Math.ceil(totalCount / limit);

        if (totalIterations > 0) {
          const responses = [...Array(totalIterations).keys()].map((num, i) => {
            const requestBody = body;
            requestBody.searchRequest = {
              ...requestBody.searchRequest,
              offset: i * limit,
            };
            return axios.post<ScatterPlotChartResponseData>(
              scatterPlotEndpoint,
              requestBody
            );
          });

          settledPromise(responses, (values) => {
            const scatterData = values.flatMap(
              (response) => response.data.wellChartData
            );

            setData(scatterData);
          });
        } else {
          setData([]);
        }
      } catch (e) {
        setData([]);
        setIsError(true);
        console.log("getChartData error", e);
      } finally {
        setIsLoading(false);
      }
    },
    [
      isSelectedAllGrid,
      selectedGridDataKeys,
      isFromUploadedUWIFile,
      uploadedUWIFile,
      getUWIFileIdWithRetry,
    ]
  );

  const formatXTicks = (
    chartData: ITraceData[],
    attributeType?: AttributeDataType
  ) => {
    if (attributeType === DATE) {
      const minmaxValue: { [key: string]: number } = {};
      chartData.forEach((data) => {
        minmaxValue[data.id] = differenceInMonths(
          new Date(data.x[0]),
          new Date(data.x[data.x.length - 1])
        );
      });

      const flatMappedXValue = chartData
        .flatMap((value) => value.x)
        .map((flattedValue) => convertToMilliseconds(flattedValue));
      const max: number = getMaxValue(flatMappedXValue);
      const min: number = getMinValue(flatMappedXValue);

      const monthDiff = differenceInMonths(new Date(min), new Date(max));
      return (
        Object.values(minmaxValue).filter((val) => val > 5).length > 0 ||
        monthDiff > 5
      );
    } else {
      return false;
    }
  };

  return {
    isLoading,
    data,
    isError,
    getChartData,
    formatTrace,
    createTrace,
    formatXTicks,
    createDefaultTrace,
    mapTraceData,
  };
};
