import { useState } from "react";

import { ScatterPlotColorByProps } from "../../types/charts/chartHooks/scatterPlotData";
import {
  ChartColorByInfo,
  ITraceData,
  NumericColorRange,
  ScatterPlotWellChartData,
} from "../../types/charts/chartType/chartType";
import { AttributeDataType } from "../../types/common/attributes";
import { GetTopAttributesResponse } from "../../types/map/mapSettings/useTopAttributeValues";

import { DATE, FLOAT, INTEGER } from "../../constants/attributes";
import {
  allOthersKey,
  maxGroup,
  maxValColor,
  minGroup,
  minValColor,
} from "../../constants/charts/scatterPlotDataFields";
import {
  DEFAULT_ATTRIBUTE_COLOR,
  DEFAULT_GRADIENT_COLORS,
} from "../../constants/map/mapSettings";

import {
  colorBinValues,
  getEqualBins,
  getGradientColors,
  getPareto,
} from "../../data/map/utils/colorAggregator";
import {
  formatColorsToRGB,
  formatDefaultColorToRGB,
  formatDefaultTopColorsToRGB,
} from "../../utils/charts/formatter";
import {
  clone,
  convertToMilliseconds,
  formatDateToString,
  formattedNumber,
} from "../../utils/helper";
import { getMaxValue, getMinValue } from "../../utils/map/wellStyling";

import { useScatterPlotChartData } from "./useScatterPlotChartData";

export const useScatterPlotColorByChartData = () => {
  const { formatTrace } = useScatterPlotChartData();
  const [partitions, setPartitions] = useState<NumericColorRange | undefined>(
    undefined
  );

  const transformData = (data: string, attrType?: AttributeDataType) => {
    switch (attrType) {
      case FLOAT:
        return parseFloat(data);
      case INTEGER:
        return parseInt(data);
      case DATE:
        return convertToMilliseconds(data);
      default:
        return data;
    }
  };

  const convertMinMaxToString = (chartColorByInfo: ChartColorByInfo) => {
    let convertMin = chartColorByInfo?.minMax?.min;
    let convertMax = chartColorByInfo?.minMax?.max;

    switch (chartColorByInfo.attibuteDataType) {
      case DATE:
        convertMax = formatDateToString(convertMax);
        convertMin = formatDateToString(convertMin);
        break;

      case INTEGER:
      case FLOAT:
        convertMax = formattedNumber(convertMax);
        convertMin = formattedNumber(convertMin);
        break;
    }

    return {
      min: convertMin as string,
      max: convertMax as string,
    };
  };

  const convertData = (dataType?: AttributeDataType, value?: string) => {
    if (!value) {
      return "";
    }
    switch (dataType) {
      case DATE:
        return formatDateToString(value);

      case INTEGER:
      case FLOAT:
        return formattedNumber(value);
      default:
        return "";
    }
  };

  const formatMinTrace = (min: string, minColor: string | number[]) => {
    const minTrace = formatTrace(min, "", "", minColor, min, true, minGroup);
    minTrace.x = [];
    minTrace.y = [];

    return minTrace;
  };

  const formatMaxTrace = (max: string, maxColor: string | number[]) => {
    const maxTrace = formatTrace(max, "", "", maxColor, max, true, maxGroup);
    maxTrace.x = [];
    maxTrace.y = [];
    return maxTrace;
  };

  const formatDefaultTrace = () => {
    const defaultColor = DEFAULT_ATTRIBUTE_COLOR;
    const nullFieldTrace = formatTrace(
      allOthersKey,
      "",
      "",
      defaultColor,
      allOthersKey,
      true
    );

    return nullFieldTrace;
  };

  const createSingeAllOthersTrace = (
    chartDataProps: ScatterPlotColorByProps
  ) => {
    const chartTrace: ITraceData[] = [];

    chartTrace.push(formatDefaultTrace());

    chartDataProps.data.forEach((obj) => {
      const objFields = obj.fields;
      const xAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.xAxisField?.name && data.val
      );
      const yAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.yAxisFields[0].name && data.val
      );

      if (xAxisFieldValue && yAxisFieldValue) {
        chartTrace.map((trace) => {
          if (trace.name === allOthersKey) {
            trace.x.push(xAxisFieldValue.val);
            trace.y.push(yAxisFieldValue.val);
          }
          return trace;
        });
      }
    });

    chartTrace.map((trace) => {
      trace.marker.color = formatColorsToRGB(trace.marker.color as number[]);
      return trace;
    });

    return chartTrace;
  };
  const createSingleMaxTrace = (chartDataProps: ScatterPlotColorByProps) => {
    const chartTrace: ITraceData[] = [];
    //get colors

    if (
      chartDataProps.chartColorByInfo.minMax &&
      chartDataProps.chartColorByInfo.minMax.max
    ) {
      const convertedValuesToString = convertMinMaxToString(
        chartDataProps.chartColorByInfo
      );
      chartTrace.push(
        formatMaxTrace(convertedValuesToString.max ?? "", maxValColor)
      );
    }

    if (chartDataProps.chartColorByInfo.minMax?.hasNull) {
      chartTrace.push(formatDefaultTrace());
    }

    chartDataProps.data.forEach((obj) => {
      const objFields = obj.fields;
      const xAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.xAxisField?.name && data.val
      );
      const yAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.yAxisFields[0].name && data.val
      );
      const colorByAttrValue = objFields.find(
        (data) =>
          data.name === chartDataProps.chartColorByInfo.attributeKey && data.val
      );

      if (xAxisFieldValue && yAxisFieldValue) {
        if (colorByAttrValue && colorByAttrValue.val) {
          chartTrace.map((trace) => {
            if (
              trace.id ===
              convertData(
                chartDataProps.chartColorByInfo.attibuteDataType,
                colorByAttrValue.val
              )
            ) {
              trace.x.push(xAxisFieldValue.val);
              trace.y.push(yAxisFieldValue.val);
            }
            return trace;
          });
        } else {
          chartTrace.map((trace) => {
            if (trace.name === allOthersKey) {
              trace.x.push(xAxisFieldValue.val);
              trace.y.push(yAxisFieldValue.val);
            }
            return trace;
          });
        }
      }
    });

    chartTrace.map((trace) => {
      trace.marker.color = formatColorsToRGB(trace.marker.color as number[]);
      return trace;
    });

    return chartTrace;
  };

  const createMultipleTraces = (chartDataProps: ScatterPlotColorByProps) => {
    const chartTrace: ITraceData[] = [];

    const colors = getGradientColors(DEFAULT_GRADIENT_COLORS).map(
      (rgba: number[]) => rgba.slice(0, 3)
    );

    let domain: any[] = [];

    domain =
      chartDataProps.chartColorByInfo.attibuteDataType === FLOAT ||
      chartDataProps.chartColorByInfo.attibuteDataType === INTEGER
        ? getPareto(
            chartDataProps.chartColorByInfo.minMax?.max as number,
            chartDataProps.chartColorByInfo.minMax?.min as number
          )
        : getEqualBins(
            chartDataProps.chartColorByInfo.minMax?.max as number,
            chartDataProps.chartColorByInfo.minMax?.min as number
          );

    domain.forEach((bin, index) => {
      const binId = `bin_${index}`;
      const fieldTrace = formatTrace(
        binId,
        "",
        "",
        colors[index],
        binId,
        false
      );
      chartTrace.push(fieldTrace);
    });

    if (chartDataProps.chartColorByInfo.minMax?.hasNull) {
      chartTrace.push(formatDefaultTrace());
    }

    chartDataProps.data.forEach((obj) => {
      const objFields = obj.fields;
      const xAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.xAxisField?.name && data.val
      );
      const yAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.yAxisFields[0].name && data.val
      );
      const colorByAttrValue = objFields.find(
        (data) =>
          data.name === chartDataProps.chartColorByInfo.attributeKey && data.val
      );

      if (xAxisFieldValue && yAxisFieldValue) {
        if (colorByAttrValue && colorByAttrValue.val) {
          const dataValue = colorByAttrValue.val;
          const colorMarker = colorBinValues(
            domain,
            colors,
            transformData(
              dataValue,
              chartDataProps.chartColorByInfo.attibuteDataType
            )
          );
          chartTrace.map((trace) => {
            if (trace.marker.color === colorMarker) {
              trace.x.push(xAxisFieldValue.val);
              trace.y.push(yAxisFieldValue.val);
            }
            return trace;
          });
        } else {
          chartTrace.map((trace) => {
            if (trace.name === allOthersKey) {
              trace.x.push(xAxisFieldValue.val);
              trace.y.push(yAxisFieldValue.val);
            }
            return trace;
          });
        }
      }
    });

    //format marker color to rgb string for plotly to recognize color
    chartTrace.map((trace, index) => {
      trace.marker.color = formatColorsToRGB(trace.marker.color as number[]);
      if (index < 11) {
        trace.legendgroup = minGroup;
      } else if (index < 20) {
        trace.legendgroup = maxGroup;
      }

      return trace;
    });

    //add min and max trace that controls the legend click on the chart
    const hasPoints = Boolean(
      chartTrace.filter((trace) => trace.x.length && trace.y.length).length
    );

    if (hasPoints) {
      const convertedValuesToString = convertMinMaxToString(
        chartDataProps.chartColorByInfo
      );

      const minTrace = formatMinTrace(
        convertedValuesToString.min,
        formatColorsToRGB(minValColor)
      );
      minTrace.x = [null];
      minTrace.y = [null];
      chartTrace.push(minTrace);

      const maxTrace = formatMaxTrace(
        convertedValuesToString.max,
        formatColorsToRGB(maxValColor)
      );
      maxTrace.x = [null];
      maxTrace.y = [null];
      chartTrace.push(maxTrace);
    }

    return chartTrace;
  };

  const getValidValues = (
    data: ScatterPlotWellChartData[],
    attributeKey?: string,
    attrType?: AttributeDataType
  ) => {
    return attributeKey
      ? data
          .flatMap((obj) => obj.fields)
          .filter((field) => field.name === attributeKey && field.val)
          .map((fieldVal) => {
            if (fieldVal.val) {
              return transformData(fieldVal.val, attrType);
            }
          })
      : [];
  };

  const getMinMaxValueByAttribute = (
    chartDataProps: ScatterPlotColorByProps
  ) => {
    const values = getValidValues(
      chartDataProps.data,
      chartDataProps.chartColorByInfo.attributeKey,
      chartDataProps.chartColorByInfo.attibuteDataType
    );
    const hasNull = values.length < chartDataProps.data.length; //if color by attribute value is null for a well
    const max: number | Date | string | null = getMaxValue(values);
    const min: number | Date | string | null = getMinValue(values);

    return {
      max: isFinite(max) ? max : null,
      min: isFinite(min) ? min : null,
      hasNull,
    };
  };

  const createVarCharTrace = (
    attributes: GetTopAttributesResponse,
    chartDataProps: ScatterPlotColorByProps
  ) => {
    const chartTrace: ITraceData[] = [];

    const chartColorPallete = formatDefaultTopColorsToRGB();
    const defaultColor = formatDefaultColorToRGB();

    //create attribute value trace
    attributes.forEach((attributeValue, index) => {
      const trace: ITraceData = formatTrace(
        attributeValue,
        "",
        "",
        attributeValue === allOthersKey
          ? defaultColor
          : chartColorPallete[index],
        attributeValue
      );
      chartTrace.push(trace);
    });

    chartDataProps.data.forEach((obj) => {
      const objFields = obj.fields;
      const xAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.xAxisField?.name && data.val
      );
      const yAxisFieldValue = objFields.find(
        (data) => data.name === chartDataProps.yAxisFields[0].name && data.val
      );
      const colorByAttrValue = objFields.find(
        (data) =>
          data.name === chartDataProps.chartColorByInfo.attributeKey && data.val
      );
      const colorByAttrTraceExists = chartTrace.find(
        (trace) => trace.name === colorByAttrValue?.val
      );

      if (xAxisFieldValue && yAxisFieldValue) {
        if (
          colorByAttrValue &&
          colorByAttrValue.val &&
          colorByAttrTraceExists
        ) {
          chartTrace.map((trace) => {
            if (trace.name === colorByAttrValue.val) {
              trace.x.push(xAxisFieldValue.val);
              trace.y.push(yAxisFieldValue.val);
            }
            return trace;
          });
        } else {
          chartTrace.map((trace) => {
            if (trace.name === allOthersKey) {
              trace.x.push(xAxisFieldValue.val);
              trace.y.push(yAxisFieldValue.val);
            }
            return trace;
          });
        }
      }
    });

    return chartTrace;
  };

  const createNumericOrDateTrace = (
    chartDataProps: ScatterPlotColorByProps
  ) => {
    let chartTrace: ITraceData[] = [];

    const { max, min, hasNull } = getMinMaxValueByAttribute(chartDataProps);
    setPartitions({ max, min, hasNull });
    const copiedChartDataProps = clone(
      chartDataProps
    ) as ScatterPlotColorByProps;
    copiedChartDataProps.chartColorByInfo.minMax = { max, min, hasNull };

    if (
      copiedChartDataProps.xAxisField &&
      copiedChartDataProps.yAxisFields.length &&
      copiedChartDataProps.chartColorByInfo.attributeKey &&
      copiedChartDataProps.chartColorByInfo.minMax
    ) {
      if (
        copiedChartDataProps.chartColorByInfo.minMax.max ===
        copiedChartDataProps.chartColorByInfo.minMax.min
      ) {
        chartTrace = createSingleMaxTrace(copiedChartDataProps);
      } else if (
        copiedChartDataProps.chartColorByInfo.minMax.min === null &&
        copiedChartDataProps.chartColorByInfo.minMax.max === null &&
        copiedChartDataProps.chartColorByInfo.minMax.hasNull
      ) {
        chartTrace = createSingeAllOthersTrace(copiedChartDataProps);
      } else {
        chartTrace = createMultipleTraces(copiedChartDataProps);
      }
    }

    return chartTrace;
  };

  return {
    createNumericOrDateTrace,
    createVarCharTrace,
    partitions,
    convertMinMaxToString,
  };
};
