import { Fragment, useEffect, useState } from "react";

import { useOktaAuth } from "@okta/okta-react";

import AppsIcon from "@mui/icons-material/Apps";
import { Menu, MenuItem, styled } from "@mui/material/";

import jwt_decode from "jwt-decode";

import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

import { withRouter } from "../../utils/routes/withRouter";

import Informations from "../Informations";
import { TGSLogo } from "../common/icons";

import { TGSUserIcon } from "../../assets/CustomIcons";

const CustomMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    marginLeft: 6,
  },
});

const AppHeader = (props) => {
  const toggleIsOpenAppLevelNav = usePanelsStore(
    (state) => state.toggleIsOpenAppLevelNav
  );
  const updateAuthUser = useStore((state) => state.updateAuthUser);

  const [loginState, setloginState] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState();

  const [userAnchor, setUserAnchor] = useState(null);
  const userOpen = Boolean(userAnchor);

  const { authState, oktaAuth } = useOktaAuth();

  const logoutHandle = async () => {
    setUserAnchor(null);
    try {
      await oktaAuth.signOut("/");
    } catch (err) {
      console.log(err, "error logout");
      props.history.push("/");
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      setloginState(true);

      const decodedToken = jwt_decode(authState.accessToken.accessToken);
      updateAuthUser(decodedToken);
      setUserLoggedIn(decodedToken.UserEmail);
    }
  }, [authState]);

  return (
    <Fragment>
      <header
        className={`arlas-cs-header-section${
          props.className ? ` ${props.className}` : ""
        } index-position`}
      >
        <div className="d-flex justify-content-between">
          <div className="left-header d-flex align-items-center">
            <div className="d-flex align-items-center logo-title">
              <AppsIcon onClick={() => toggleIsOpenAppLevelNav()} />
              <a
                className="tgs-logo ml-5"
                href="https://www.tgs.com/products-services/well-data"
                target="_blank"
                rel="noreferrer"
              >
                <TGSLogo />
              </a>
              <p className="arlas-cs-title">Well Data Analytics</p>
            </div>
          </div>

          <div className="right-header d-flex">
            <Informations />
            <Fragment>
              <div className="userIconGroup">
                {loginState !== false ? (
                  <button
                    onClick={(event) => setUserAnchor(event.currentTarget)}
                    className="userIcon"
                  >
                    <TGSUserIcon width="22px" height="22px" />
                  </button>
                ) : null}
              </div>
              <CustomMenu
                id="long-menu"
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                anchorEl={userAnchor}
                open={userOpen}
                onClose={() => setUserAnchor(null)}
              >
                <MenuItem disabled className="user-logged-in">
                  <p>{userLoggedIn}</p>
                </MenuItem>
                <MenuItem onClick={logoutHandle}>Logout</MenuItem>
              </CustomMenu>
            </Fragment>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default withRouter(AppHeader);
