import { StateCreator } from "zustand";

import {
  AnalysisPanelSlice,
  AppLevelNavSlice,
  LeftSidePanelSlice,
  QBEditorPanelSlice,
  SearchPanelTabSlice,
  WellPanelSlice,
} from "../../types/panels/store";

import { ATTRIBUTES_TAB } from "../../constants/constants";
import { SIDEBAR_MENU_TEXTS } from "../../constants/panels/menu";
import { WELL_PANEL_SECTION } from "../../constants/panels/wellPanel/wellInfo";

import { convertUWI } from "../../utils/common/wells";

const appLevelNavSlice: StateCreator<AppLevelNavSlice> = (set, get) => ({
  isOpenAppLevelNav: true,
  toggleIsOpenAppLevelNav: (status) =>
    set(() => ({ isOpenAppLevelNav: status ?? !get().isOpenAppLevelNav })),
});

const leftSidePanelSlice: StateCreator<LeftSidePanelSlice> = (set, get) => ({
  isOpenLeftSidePanel: true,
  toggleLeftSidePanel: (status) =>
    set(() => ({ isOpenLeftSidePanel: status ?? !get().isOpenLeftSidePanel })),
  activePanel: SIDEBAR_MENU_TEXTS.DEFAULT_CHARTS,
  updateActivePanel: (activePanel) => set(() => ({ activePanel })),
});

const searchPanelTabSlice: StateCreator<SearchPanelTabSlice> = (set, get) => ({
  activeSearchPanelTab: ATTRIBUTES_TAB,
  updateActiveSearchPanelTab: (activeSearchPanelTab) =>
    set(() => ({ activeSearchPanelTab })),
});

const qbEditorPanelSlice: StateCreator<QBEditorPanelSlice> = (set, get) => ({
  isOpenQBEditorPanel: false,
  toggleIsOpenQBEditorPanel: (status) =>
    set(() => ({
      isOpenQBEditorPanel: status ?? !get().isOpenQBEditorPanel,
    })),
});

const analysisPanelSlice: StateCreator<AnalysisPanelSlice> = (set, get) => ({
  isOpenAnalysisPanel: false,
  toggleAnalysisPanel: (status) =>
    set(() => ({ isOpenAnalysisPanel: status ?? !get().isOpenAnalysisPanel })),
});

const wellPanelSlice: StateCreator<WellPanelSlice> = (set, get) => ({
  wellList: [],
  isWellPanelNavCollapsed: false,
  activeWellPanelInfo: undefined,
  updateActiveWellPanelInfo: (wellPanel) =>
    set(() => ({ activeWellPanelInfo: wellPanel })),
  toggleIsWellPanelNavCollapsed: (status) =>
    set(() => ({
      isWellPanelNavCollapsed: status ?? !get().isWellPanelNavCollapsed,
    })),
  updateActiveWellPanel: (status, wellInfo) =>
    set(() => ({
      wellList: get().wellList.map((well) => {
        if (!wellInfo) {
          well.isActive = status;
          return well;
        }

        if (
          well.groupedWellID === wellInfo.groupedWellID &&
          well.wellType === wellInfo.wellType
        ) {
          well.isActive = status;
        } else {
          well.isActive = false;
        }
        return well;
      }),
    })),
  updateWellPanelActiveTabByKey: (layer, groupedWellID, wellId, tab) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.wellSystemData?.map((wellData) => {
            if (wellData.wellId === wellId) {
              return (wellData.activeTab = tab);
            }
          });
        }

        return currentWellInfo;
      }),
    })),
  updateWellPanelActiveSectionByKey: (layer, groupedWellID, wellId, section) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.wellSystemData?.map((wellData) => {
            if (wellData.wellId === wellId) {
              switch (section.key) {
                case WELL_PANEL_SECTION.HEADER_SECTION.key:
                  return (wellData.pageSectionStatus.isHeaderSectionOpen =
                    !wellData.pageSectionStatus.isHeaderSectionOpen);
                case WELL_PANEL_SECTION.STATISTICS_SECTION.key:
                  return (wellData.pageSectionStatus.isStatisticsSectionOpen =
                    !wellData.pageSectionStatus.isStatisticsSectionOpen);
                case WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION.key:
                  return (wellData.pageSectionStatus.isDeclineCurveParametersOpen =
                    !wellData.pageSectionStatus.isDeclineCurveParametersOpen);
                case WELL_PANEL_SECTION.PERFORATION_SECTION.key:
                  return (wellData.pageSectionStatus.isPerfSectionOpen =
                    !wellData.pageSectionStatus.isPerfSectionOpen);
                case WELL_PANEL_SECTION.TEST_SECTION.key:
                  return (wellData.pageSectionStatus.isTestSectionOpen =
                    !wellData.pageSectionStatus.isTestSectionOpen);
                case WELL_PANEL_SECTION.PRESSURE_DATA_SECTION.key:
                  return (wellData.pageSectionStatus.isPressureDataOpen =
                    !wellData.pageSectionStatus.isPressureDataOpen);
                case WELL_PANEL_SECTION.COMPLETION_SECTION.key:
                  return (wellData.pageSectionStatus.isCompletionSectionOpen =
                    !wellData.pageSectionStatus.isCompletionSectionOpen);
                case WELL_PANEL_SECTION.CASING_SECTION.key:
                  return (wellData.pageSectionStatus.isCasingSectionOpen =
                    !wellData.pageSectionStatus.isCasingSectionOpen);
                case WELL_PANEL_SECTION.PRODUCTION_PLOT_SECTION.key:
                  return (wellData.pageSectionStatus.isProductionPlotSectionOpen =
                    !wellData.pageSectionStatus.isProductionPlotSectionOpen);
                case WELL_PANEL_SECTION.GUN_BARREL_SECTION.key:
                  return (wellData.pageSectionStatus.isGunBarrelSectionOpen =
                    !wellData.pageSectionStatus.isGunBarrelSectionOpen);
                case WELL_PANEL_SECTION.WELL_ECONOMICS_SECTION.key:
                  return (wellData.pageSectionStatus.isWellEconomicsOpen =
                    !wellData.pageSectionStatus.isWellEconomicsOpen);
                case WELL_PANEL_SECTION.FORMATION_SECTION.key:
                  return (wellData.pageSectionStatus.isFormationSectionOpen =
                    !wellData.pageSectionStatus.isFormationSectionOpen);
                case WELL_PANEL_SECTION.PERMIT_SECTION.key:
                  return (wellData.pageSectionStatus.isPermitSectionOpen =
                    !wellData.pageSectionStatus.isPermitSectionOpen);
                default:
                  return;
              }
            }
          });
        }

        return currentWellInfo;
      }),
    })),

  updateWellCardDataByKey: (wellCardData, layer, groupedWellID, wellId) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.wellSystemData = wellCardData;
          currentWellInfo.activePage =
            wellCardData.find((data) => data.wellId === wellId)?.pageNumber ??
            0;
        }

        return currentWellInfo;
      }),
    })),
  updateIsSurfaceBottomEqual: (isEqual, layer, groupedWellID) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.isSurfaceAndBottomEqual = isEqual;
        }

        return currentWellInfo;
      }),
    })),

  updateWellCardDataByTypeAndKey: (
    wellCardDataByType,
    wellId,
    layer,
    groupedWellID
  ) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.wellSystemData?.map((wellData) => {
            if (wellData.wellId === wellId) {
              switch (wellCardDataByType.type) {
                case "perforation":
                  return (wellData.perforationData =
                    wellCardDataByType.perforation);
                case "test":
                  return (wellData.testingData = wellCardDataByType.test);
                case "casing":
                  return (wellData.casingData = wellCardDataByType.casing);
                case "gunbarrel":
                  return (wellData.gunBarrelData =
                    wellCardDataByType.gunBarrel);
                case "productionplot":
                  return (wellData.productionData = {
                    trace: wellCardDataByType.trace,
                    customTicks: wellCardDataByType.customTicks,
                    isDataLoaded: wellCardDataByType.isDataLoaded,
                  });
                case "welleconomics":
                  return (wellData.wellEconomicsData =
                    wellCardDataByType.wellEconomics);
                case "formation":
                  return (wellData.formationData = {
                    isDataLoaded: wellCardDataByType.isDataLoaded,
                    formation: wellCardDataByType.formation,
                  });
                case "permit":
                  return (wellData.permitsData = {
                    isDataLoaded: wellCardDataByType.isDataLoaded,
                    permit: wellCardDataByType.permit,
                  });
                case "logs":
                  return (wellData.logData = wellCardDataByType.logs);
                case "docs":
                  return (wellData.docsData = wellCardDataByType.docs);
                case "pressuredata":
                  return (wellData.pressureData =
                    wellCardDataByType.pressureData);
                default:
                  return;
              }
            }
          });
        }

        return currentWellInfo;
      }),
    })),
  updateWellCardDataByUWI: (wellCardDataByType, uwi, layer, groupedWellID) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.wellSystemData?.map((wellData) => {
            if (convertUWI(wellData.wellCardData.uwi as string, -2) === uwi) {
              switch (wellCardDataByType.type) {
                case "pressuredata":
                  if (!wellData.pressureData) {
                    return (wellData.pressureData =
                      wellCardDataByType.pressureData);
                  }
                  return;
                default:
                  return;
              }
            }
          });
        }

        return currentWellInfo;
      }),
    })),

  updateWellPanelPaginationByKey: (layer, groupedWellID, activePage) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.activePage = activePage;
        }

        return currentWellInfo;
      }),
    })),
  updateActiveWellPanelByKey: (layer, groupedWellID) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellType === layer &&
          currentWellInfo.groupedWellID === groupedWellID
        ) {
          currentWellInfo.isActive = true;
        } else {
          currentWellInfo.isActive = false;
        }

        return currentWellInfo;
      }),
    })),

  updateWellList: (wellInfo) =>
    set(() => ({
      wellList: [
        ...get().wellList.map((well) => {
          well.isActive = false;
          return well;
        }),
        wellInfo,
      ],
    })),
  removeWellPanelByKey: (layer, groupedWellID) =>
    set(() => {
      const newWellList = get().wellList.filter(
        (wellData) =>
          !(
            wellData.wellType === layer &&
            wellData.groupedWellID === groupedWellID
          )
      );
      return {
        wellList: newWellList,
      };
    }),
  showAlertMessage: false,
  toggleAlertMessage: (status) =>
    set(() => ({
      showAlertMessage: status ?? !get().showAlertMessage,
    })),
  showExportMessage: false,
  showExportPlotMessage: false,
  toggleExportMessage: (status) =>
    set(() => ({ showExportMessage: status ?? !get().showExportMessage })),
  toggleExportPlotMessage: (status) =>
    set(() => ({
      showExportPlotMessage: status ?? !get().showExportPlotMessage,
    })),

  updateGunBarrelLoaded: (data, wellId) =>
    set(() => ({
      wellList: get().wellList.map((currentWellInfo) => {
        if (
          currentWellInfo.wellId === wellId &&
          currentWellInfo.isActive &&
          data.pageNumber === currentWellInfo.activePage
        ) {
          if (!data.isGunBarrelLoaded) {
            data.isGunBarrelLoaded = true;
          }
        }
        return currentWellInfo;
      }),
    })),
  selectedWellCardPWIDs: [],
  addSelectedWellCardPWID: (selectedWellCardPWIDs) =>
    set((state) => ({
      selectedWellCardPWIDs: [
        ...get().selectedWellCardPWIDs,
        selectedWellCardPWIDs,
      ],
    })),
  removeSelectedWellCardByPWID: (parentWellId) =>
    set((state) => ({
      selectedWellCardPWIDs: get().selectedWellCardPWIDs.filter(
        (data) => data !== parentWellId
      ),
    })),

  selectedWellCardBWIDs: [],
  addSelectedWellCardBWID: (selectedWellCardBWID: number) =>
    set((state) => ({
      selectedWellCardBWIDs: [
        ...get().selectedWellCardBWIDs,
        selectedWellCardBWID,
      ],
    })),
  addMultipleSelectedWellCardBWID: (selectedWellCardBWIDs: number[]) =>
    set((state) => ({
      selectedWellCardBWIDs: [
        ...get().selectedWellCardBWIDs,
        ...selectedWellCardBWIDs,
      ],
    })),
  removeSelectedWellCardByBWID: (bwid) =>
    set((state) => ({
      selectedWellCardBWIDs: get().selectedWellCardBWIDs.filter(
        (data) => data !== bwid
      ),
    })),
  removeMultipleSlectedWellCardByBWID: (bwids) =>
    set((state) => ({
      selectedWellCardBWIDs: get().selectedWellCardBWIDs.filter(
        (data) => !bwids.includes(data)
      ),
    })),
  resetWellList: () =>
    set(() => ({
      wellList: [],
      selectedWellCardPWIDs: [],
      selectedWellCardBWIDs: [],
    })),
  isPerforationsLoading: false,
  updateIsPerforationsLoading: (isPerforationsLoading) =>
    set(() => ({ isPerforationsLoading })),
  isTestLoading: false,
  updateIsTestLoading: (isTestLoading) => set(() => ({ isTestLoading })),
  isCasingLoading: false,
  updateIsCasingLoading: (isCasingLoading) => set(() => ({ isCasingLoading })),
  isFormationsLoading: false,
  updateIsFormationsLoading: (isFormationsLoading) =>
    set(() => ({ isFormationsLoading })),
  isPermitsLoading: false,
  updateIsPermitsLoading: (isPermitsLoading) =>
    set(() => ({ isPermitsLoading })),
  isPressureDataLoading: false,
  updateIsPressureDataLoading: (isPressureDataLoading) =>
    set(() => ({ isPressureDataLoading })),
});

export {
  appLevelNavSlice,
  leftSidePanelSlice,
  searchPanelTabSlice,
  qbEditorPanelSlice,
  analysisPanelSlice,
  wellPanelSlice,
};
