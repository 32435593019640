import { useCallback, useEffect, useMemo } from "react";

import { useOktaAuth } from "@okta/okta-react";

import LayerGroup from "ol/layer/Group";

import { UpdateLayersType } from "../../types/map/customHooks/useUpdateMapLayer";

import { DYNAMIC_VECTOR_TILES } from "../../constants/constants";

import useMapStore from "../../store/map/mapStore";

import { getToken } from "../../utils/common/getToken";
import {
  getNonDVTLayerTitlesToCreate,
  removeUnselectedNonDVTLayersFromMap,
} from "../../utils/map/layers/layerUtils";

import {
  createLayerFromLayerDefinition,
  getLayerDefinitionsKeys,
  getLayerGroupNamesByFetchType,
} from "../../utils";

const useUpdateNonDVTLayers = (selectedLayerNames: string[]) => {
  const { authState } = useOktaAuth();

  const map = useMapStore((state) => state.map);

  const selectedLayersWithNoDVT = useMemo(() => {
    const layerNamesWithDVT = getLayerGroupNamesByFetchType(
      getLayerDefinitionsKeys(),
      DYNAMIC_VECTOR_TILES
    );

    return selectedLayerNames.filter(
      (layerName) => !layerNamesWithDVT.includes(layerName)
    );
  }, [selectedLayerNames]);

  const stringifiedSelectedLayersWithNODVT = useMemo(
    () => JSON.stringify(selectedLayersWithNoDVT),
    [selectedLayersWithNoDVT]
  );

  const updateMapLayers: UpdateLayersType = useCallback(async () => {
    const token = getToken(authState);
    if (!map || !token) return;

    const layersToRemove = removeUnselectedNonDVTLayersFromMap(
      map,
      selectedLayersWithNoDVT
    );

    // Add selected layers that are not currently in the map
    const layersTitlesToCreate = getNonDVTLayerTitlesToCreate(
      map,
      selectedLayersWithNoDVT
    );

    const layersToDisplay = layersTitlesToCreate.map(
      async (layerTitleToDisplay) => {
        return await createLayerFromLayerDefinition({
          definitionKey: layerTitleToDisplay,
          token: getToken(authState),
        });
      }
    );

    const waitedLayersToDisplay = (await Promise.all(
      layersToDisplay
    )) as LayerGroup[];

    waitedLayersToDisplay.forEach((layerToDisplay) => {
      map.addLayer(layerToDisplay);
    });

    return { layersToRemove, waitedLayersToDisplay };
  }, [map, authState, selectedLayersWithNoDVT]);

  useEffect(() => {
    if (!map) return;
    updateMapLayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedSelectedLayersWithNODVT]);
};

export default useUpdateNonDVTLayers;
