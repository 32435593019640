import { union } from "lodash";
import { StateCreator } from "zustand";

import {
  MapOverlaySlice,
  MapSettingsStates,
} from "../../../types/map/mapSettings/store";

const sliceResetFns = new Set<() => void>();

const resetMapOverlaySlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialMapOverlayStates = {
  mapOverlayOpened: [],
};

const mapOverlaySlice: StateCreator<
  MapSettingsStates,
  [],
  [],
  MapOverlaySlice
> = (set) => {
  sliceResetFns.add(() => set(initialMapOverlayStates));

  return {
    ...initialMapOverlayStates,
    updateMapOverlayOpened: (overlay) =>
      set(() => ({
        mapOverlayOpened: overlay,
      })),

    appendMapOverlayOpened: (panelKey) =>
      set((state) => ({
        mapOverlayOpened: union(state.mapOverlayOpened, [panelKey]),
      })),

    removeMapOverlayOpened: (panelKey) =>
      set((state) => ({
        mapOverlayOpened: state.mapOverlayOpened.filter(
          (overlay) => panelKey !== overlay
        ),
      })),
  };
};

export { mapOverlaySlice, resetMapOverlaySlice };
