import { withRouter } from "../../utils/routes/withRouter";

import SidebarNavPanel from "../panels/SidebarNavPanel";
import AppHeader from "./AppHeader";

const AppLayout = (props) => {
  return (
    <>
      <AppHeader />
      <main role="main">
        <div className="app-layout">
          <SidebarNavPanel />
          {props.children}
        </div>
      </main>
    </>
  );
};

export default withRouter(AppLayout);
