import { create } from "zustand";

import { UWISearchStore } from "../../../types/panels/searchPanel/uwiSearch";

// import { ExportCardStore } from "../../types/exports/export-card/exportCardState";
import { fileUwiSearch, pastedUwiSearch, resetUwis } from "./searchUWISlice";

const useUWISearchStore = create<UWISearchStore>()((...a) => ({
  ...fileUwiSearch(...a),
  ...pastedUwiSearch(...a),
  ...resetUwis(...a),
}));

export default useUWISearchStore;
