import { AxisType, Dash } from "plotly.js";

import {
  ChartRange,
  YAxisScaleType,
} from "../../../types/charts/chartType/chartType";

import {
  CHART_SCALING_TYPE,
  YYYYTickFormat,
  mmmYYYYTickFormat,
} from "../../../constants/charts/charts";

import { transformToTick } from "../..";

export const config = {
  responsive: true,
  displayModeBar: false,
  edits: {
    legendPosition: true,
  },
};
export const generalLayout = (isForFullScreenView: boolean) => {
  return {
    autosize: true,
    paper_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    plot_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    showlegend: true,
  };
};

export const marginLayout = {
  t: 55,
  b: 40,
  l: 60,
  r: 20,
};

export const fontLayout = {
  size: 12,
  family: "Roboto, Arial",
};

export const legendLayout = (
  range: ChartRange | undefined,
  isForFullScreenView?: boolean
) => {
  return {
    ...(range?.xLegend && {
      x: range?.xLegend,
    }),
    ...(range?.yLegend && {
      y: range?.yLegend,
    }),
    font: {
      color: "#838383",
      size: isForFullScreenView ? 18 : 12,
    },
    ...(isForFullScreenView && {
      y: -0.15,
      x: 0,
    }),
  };
};

export const xAxisLayout = (
  range: ChartRange | undefined,
  shouldFormatXTicks: boolean,
  isForFullScreenView?: boolean
) => {
  const gridDash: Dash = "dot";
  const isValid = (currentValue: number | undefined | null) =>
    currentValue !== undefined && currentValue !== null;
  const hasRange =
    range && range?.xRange && (range?.xRange as []).every(isValid);

  return {
    title: {
      text: "Date",
      font: {
        family: "Roboto, Arial",
        color: "rgba(117, 117, 117, 1)",
        size: isForFullScreenView ? 18 : 12,
      },
      standoff: 10,
    },
    ...(isForFullScreenView && {
      tickfont: {
        size: 16,
      },
    }),
    automargin: true,
    color: "rgba(255, 255, 255, 0.7)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: gridDash,
    gridcolor: "rgba(117, 117, 117, 1)",
    ...(hasRange
      ? {
          range: range.xRange,
          autorange: false,
        }
      : { autorange: true }),
    tickformat: mmmYYYYTickFormat,
    tickangle: 45,
    ...(shouldFormatXTicks && { dtick: "M1" }),
  };
};

export const yAxisLayout = (
  scaling: YAxisScaleType | undefined,
  range: ChartRange | undefined,
  tick: number[],
  showDailyValues: boolean,
  isForFullScreenView?: boolean
) => {
  const gridDash: Dash = "dot";
  const mode: "auto" | "linear" | "array" = "array";
  const showexponent: "all" | "first" | "last" | "none" = "none";
  const type: AxisType = "log";
  const exponentformat: "none" | "e" | "E" | "power" | "SI" | "B" = "power";
  const isValid = (currentValue: number | undefined | null) =>
    currentValue !== undefined && currentValue !== null;
  const hasRange =
    range && range?.yRange && (range?.yRange as []).every(isValid);

  return {
    ...(scaling === CHART_SCALING_TYPE.LINEAR && {
      tickmode: mode, // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
      tickvals: tick,
      ticktext: tick?.map((tickData) => transformToTick(tickData)),
      ...(isForFullScreenView && {
        tickfont: {
          size: 16,
        },
      }),
    }),
    ...(hasRange
      ? {
          range: range.yRange,
          autorange: false,
        }
      : { autorange: true }),
    hoverformat: ",.0f",
    automargin: isForFullScreenView ? false : true,
    title: {
      text: showDailyValues
        ? "Daily Production Rate / Volume"
        : "Monthly Production Rate / Volume",
      font: {
        family: "Roboto, Arial",
        color: "rgba(117, 117, 117, 1)",
        size: isForFullScreenView ? 18 : 12,
      },
      standoff: isForFullScreenView ? 20 : 0,
    },
    size: 8,
    showline: true,
    color: "rgba(255, 255, 255, 0.7)",
    gridcolor: "rgba(117, 117, 117, 1)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: gridDash,

    ...(scaling === CHART_SCALING_TYPE.LOGARITHMIC && {
      type: type,
      showexponent: showexponent,
      exponentformat: exponentformat,
    }),
  };
};
