import { useEffect, useState } from "react";

import { CircularProgress, Drawer, IconButton } from "@mui/material";

import { SIDEBAR_MENU, SIDEBAR_MENU_TEXTS } from "../../constants/panels/menu";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

import useSideBarNavPanel from "../../customHooks/panels/useSideBarNavPanel";
import useGetSavedSearch from "../../customHooks/savedSearch/getSavedSearch/useGetSavedSearch";

import Loading from "../common/Loading";
import SidebarWellPanelNav from "./SidebarWellPanelNav";
import PanelControls from "./common/PanelControl";
import DashboardChartsPanel from "./dashboardChartsPanel/DashboardChartsPanel";
import ExportPackageManager from "./exportManager/ExportPackageManager";
import SavedSearchPanel from "./savedSearchPanel/SavedSearchPanel";
import SearchPanel from "./searchPanel/SearchPanel";
import WellPanelDrawer from "./wellPanel/WellPanelDrawer";

const SidebarNavPanel = () => {
  const toggleEnableZoomFit = useStore((state) => state.toggleEnableZoomFit);
  const updateSavedSearchFileName = useStore(
    (state) => state.updateSavedSearchFileName
  );
  const updateFetchedSavedSearchTrigger = useStore(
    (state) => state.updateFetchedSavedSearchTrigger
  );

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const activePanel = usePanelsStore((state) => state.activePanel);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);

  const exportManagerLoading = useExportCardStore(
    (state) => state.exportManagerLoading
  );

  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

  const {
    isLoading: savedSearchDataLoading,
    data: savedSearchData,
    error: savedSearchDataError,
    getSavedSearch,
  } = useGetSavedSearch();

  const {
    openSidebarPanel,
    wellsAndPermitPanel,
    handleMenuClick,
    handleCloseMenuClick,
    getIsDisabledMenu,
  } = useSideBarNavPanel();

  useEffect(() => {
    if (!savedSearchDataLoading && !savedSearchDataError && savedSearchData) {
      // Temporarily added a type here. To be moved on types
      updateSavedSearchFileName(
        (savedSearchData as { searchName: string }).searchName
      );
      toggleEnableZoomFit(true);
      updateFetchedSavedSearchTrigger(false);
    }
  }, [
    savedSearchDataLoading,
    savedSearchData,
    savedSearchDataError,
    updateSavedSearchFileName,
    toggleEnableZoomFit,
    updateFetchedSavedSearchTrigger,
  ]);

  useEffect(() => {
    if (
      !wellsAndPermitPanel.length &&
      activePanel === SIDEBAR_MENU_TEXTS.WELL_PANEL
    ) {
      updateActivePanel(SIDEBAR_MENU_TEXTS.DEFAULT_CHARTS);
    }
  }, [wellsAndPermitPanel.length]);

  return (
    <>
      <Drawer
        ref={setPanelRef}
        anchor="left"
        open={isOpenAppLevelNav}
        className="sidebar-drawer-style"
        disableEnforceFocus
        id="app-level-navigation-drawer"
        hideBackdrop
      >
        <div className="sidebar-menu">
          {SIDEBAR_MENU.map((menu) => {
            return (
              !menu.hidden && (
                <IconButton
                  key={menu.text}
                  sx={{
                    ...menu.style,
                  }}
                  className={`menu-icon-box ${
                    exportManagerLoading ? `menu-${menu.text}` : ""
                  } ${activePanel === menu.text ? "active-menu" : ""}`}
                  onClick={() => handleMenuClick(menu.text)}
                  disabled={getIsDisabledMenu(menu.text)}
                >
                  {menu.text === SIDEBAR_MENU_TEXTS.EXPORT_PACKAGE_MANAGER &&
                  exportManagerLoading ? (
                    <Loading size={25} />
                  ) : (
                    menu.icon
                  )}
                </IconButton>
              )
            );
          })}
          {wellsAndPermitPanel.length !== 0 && <SidebarWellPanelNav />}
        </div>
        {!isOpenLeftSidePanel && (
          <PanelControls
            anchorEl={panelRef}
            open={isOpenLeftSidePanel}
            clickCallback={() => {
              handleCloseMenuClick();
            }}
          />
        )}
      </Drawer>
      <DashboardChartsPanel
        open={openSidebarPanel.dashboardPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <SearchPanel
        open={openSidebarPanel.searchPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <SavedSearchPanel
        open={openSidebarPanel.savedSearchPanel}
        getSavedSearch={getSavedSearch}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <WellPanelDrawer
        isOpenWellPanel={openSidebarPanel.wellPanel}
        handleCloseMenuClick={handleCloseMenuClick}
      />
      <ExportPackageManager />
    </>
  );
};

export default SidebarNavPanel;
