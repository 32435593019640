import {
  SavedSearchRounded as SavedSearchRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

import { SidebarNavigationMenu } from "../../types/panels/sidebar";

import { DashboardChartsIcon } from "../../components/common/icons";
import ExportPackageManagerIcon from "../../components/common/icons/ExportPackageManagerIcon";

export const SIDEBAR_MENU_TEXTS = {
  DEFAULT_CHARTS: "default_charts",
  SEARCH: "search",
  SAVED_SEARCH: "savedsearch",
  EXPORT_PACKAGE_MANAGER: "export_package_manager",
  WELL_PANEL: "wellpanel",
} as const;

export const SIDEBAR_MENU: SidebarNavigationMenu[] = [
  {
    text: SIDEBAR_MENU_TEXTS.DEFAULT_CHARTS,
    icon: <DashboardChartsIcon />,
    hidden: false,
    style: { padding: "10px" },
  },
  {
    text: SIDEBAR_MENU_TEXTS.SEARCH,
    icon: <SearchIcon />,
    hidden: false,
    style: { padding: "15px" },
  },
  {
    text: SIDEBAR_MENU_TEXTS.SAVED_SEARCH,
    icon: <SavedSearchRoundedIcon />,
    hidden: false,
    style: { padding: "15px" },
  },
  {
    text: SIDEBAR_MENU_TEXTS.EXPORT_PACKAGE_MANAGER,
    icon: <ExportPackageManagerIcon />,
    hidden: false,
    style: { padding: "12px" },
  },
];
