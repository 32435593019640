import { useMemo } from "react";

import { PermitPanelInfo } from "../../../types/panels/permitPanel/permitPanel";
import { WellPermitPanelState } from "../../../types/panels/sidebar";

import { SIDEBAR_MENU_TEXTS } from "../../../constants/panels/menu";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useMapHoverStore from "../../../store/map/hover/mapHoverStore";
import usePanelsStore from "../../../store/panels/panelsStore";

const usePermitPanelViewAction = () => {
  const mapHoverData = useMapHoverStore((state) => state.mapHoverData);

  const wellInfoList = usePanelsStore((state) => state.wellList);
  const permitsInfoList = usePanelsStore((state) => state.permitsList);
  const updatePermitsList = usePanelsStore((state) => state.updatePermitsList);
  const toggleAlertMessage = usePanelsStore(
    (state) => state.toggleAlertMessage
  );
  const toggleIsWellPanelNavCollapsed = usePanelsStore(
    (state) => state.toggleIsWellPanelNavCollapsed
  );
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateActiveWellPanel = usePanelsStore(
    (state) => state.updateActiveWellPanel
  );
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );
  const addSelectedCardPermitID = usePanelsStore(
    (state) => state.addSelectedCardPermitID
  );

  const isPermitSystemExisting = useMemo(() => {
    if (permitsInfoList.length && mapHoverData) {
      return permitsInfoList.find(
        (permit) =>
          permit.wellHeaderPermitKey === mapHoverData.info.WellHeaderPermitKey
      );
    }

    return undefined;
  }, [permitsInfoList, mapHoverData]);

  const isPermitSystemActivelyViewing = useMemo(() => {
    if (isPermitSystemExisting && isPermitSystemExisting.isActive) return true;
    return false;
  }, [isPermitSystemExisting, isPermitSystemExisting?.isActive]);

  const shouldDisablePermitCardButton = useMemo(
    () => Boolean(isPermitSystemActivelyViewing),
    [isPermitSystemActivelyViewing]
  );

  const handleOpenPermitPanel = () => {
    if (mapHoverData?.info.PermitID && mapHoverData.info.WellHeaderPermitKey) {
      if (!isPermitSystemExisting) {
        const wellsAndPermitPanel: WellPermitPanelState[] = [
          ...wellInfoList,
          ...permitsInfoList,
        ];

        if (wellsAndPermitPanel.length === 10) {
          toggleAlertMessage(true);
          return;
        }

        const { PermitID, UWI, WellHeaderPermitKey, WellNumber, Country } =
          mapHoverData.info;

        const permitPanelInfo: PermitPanelInfo = {
          isActive: true,
          recordType: RECORD_TYPES.PERMIT,
          createdData: new Date(),
          wellHeaderPermitKey: WellHeaderPermitKey,
          activePage: 1,
          uwi: UWI ?? "",
          wellNumber: WellNumber ?? "",
          permitID: PermitID,
          country: Country,
        };

        updatePermitsList(permitPanelInfo);
        updatePermitSpotSize(permitPanelInfo);
      } else {
        updateActivePermitPanel(true, isPermitSystemExisting);
      }

      toggleIsWellPanelNavCollapsed(true);
      toggleLeftSidePanel(true);
      updateActiveWellPanel(false);
      updateActivePanel(SIDEBAR_MENU_TEXTS.WELL_PANEL);
    }
  };

  const updatePermitSpotSize = (permitInfo: PermitPanelInfo) => {
    if (!mapHoverData?.layer) return;

    if (permitInfo && !selectedCardPermitIDs.includes(permitInfo.permitID)) {
      addSelectedCardPermitID(permitInfo.permitID);
    }
  };

  return {
    shouldDisablePermitCardButton,
    handleOpenPermitPanel,
  };
};

export default usePermitPanelViewAction;
