import { useState } from "react";

import { AxiosError } from "axios";

import { APIErrorResponse } from "../../types/common/api";

const useSavedSearchHelper = () => {
  const [error, setError] = useState<string | null>(null);

  const catchError = (errorResp: AxiosError<APIErrorResponse>) => {
    if (errorResp.response) {
      // status code out of the range of 2xx
      console.error(errorResp.response.data.error);
      setError(errorResp.response.data.error);
    } else if (errorResp.request) {
      // The request was made but no response was received
      console.error(errorResp.request);
      setError("An error occurred.");
    } else {
      // Error on setting up the request
      console.error(errorResp.message);
      setError(errorResp.message);
    }
  };

  return {
    error,
    setError,
    catchError,
  };
};

export default useSavedSearchHelper;
