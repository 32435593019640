import { useState } from "react";

import axios from "axios";

import { SearchRequestPayload } from "../../types/common/api";
import { BaseSearchCriteria } from "../../types/common/search";
import {
  GetAttribWellCountProps,
  GetAttribWellCountResponse,
} from "../../types/common/useSearchData";
import { AttributeSortKeys } from "../../types/panels/searchPanel/queryBuilder/sortPerAttribute";

import config from "../../configs/appSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useQueryBuilderStore from "../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { settledPromise } from "../../utils/api/request";

import { callServiceAPI } from "../../action/callServiceAPI";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useAttributeOptionsPostProcess from "./useAttributeOptionsPostProcess";
import useSearchRequest from "./useSearchRequest";

const useAttributeCountData = () => {
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { formatOptions, processOptions } = useAttributeOptionsPostProcess();
  const { buildSearchRequestByParam } = useSearchRequest();

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const updateColumnFilterAttributes = useDataGridStore(
    (state) => state.updateColumnFilterAttributes
  );
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const updateCurrentQBInfo = useQueryBuilderStore(
    (state) => state.updateCurrentQBInfo
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const getAttributeCount = async ({
    bounds = [],
    searchType,
    drawnPolygons = [],
    forGridColumnFilter = false,
    isBasedOnUWIFileUpload = true,
    filters = [],
    searchedUWIs = [],
    fileId = "",
    shapeId = "",
    sort,
  }: GetAttribWellCountProps) => {
    setIsLoading(true);
    setError("");
    setSuccess(false);

    const countUrl = `${config.endpoints.wellService}api/wells/count/${searchType}`;

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons,
      shapeId,
      currentBounds: bounds,
      fileId,
      searchedUWIs,
      filters,
    };

    const searchRequestBody = buildSearchRequestByParam(
      requestBodySearchCriteria
    );

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: 0,
      pageLimit: 1,
    };

    if (sort) {
      body.sort = sort;
    }

    try {
      const initialResponse = await callServiceAPI<GetAttribWellCountResponse>(
        countUrl,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        isFromUploadedUWIFile,
        isBasedOnUWIFileUpload
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const totalCount = initialResponse?.data?.totalCount;
      const pageLimit = config.countPageLimit;
      const totalIterations = Math.ceil(totalCount / pageLimit);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((_, i) => {
          return axios.post<GetAttribWellCountResponse>(
            countUrl,
            {
              ...body,
              offset: i * pageLimit,
              pageLimit,
            },
            {
              ignoreResponseInterceptor: true,
            }
          );
        });

        settledPromise(responses, (values) => {
          const allData = values.flatMap((response) => response.data.countData);

          if (forGridColumnFilter) {
            const blankIndex = allData.findIndex(
              (el) => el.attributeValue === "Blank"
            );

            //check if index exists and if it is not at the bottom of list
            if (blankIndex >= 0 && blankIndex + 1 < allData.length) {
              //get blank item and push it at the bottom of list
              allData.push(allData.splice(blankIndex, 1)[0]);
            }

            updateColumnFilterAttributes(searchType, allData);
          } else {
            const attributeOptions = allData.filter(
              (countDataElement) =>
                countDataElement.attributeValue !== "Blank" &&
                countDataElement.attributeValue !== ""
            );

            //post process options based on attribute
            const processedAttrOptions = processOptions(
              searchType,
              attributeOptions
            );

            //format options based on attribute data type
            const formattedAttrOptions = formatOptions(
              searchType,
              processedAttrOptions
            );

            updateCurrentQBInfo(searchType, "options", formattedAttrOptions);

            if (sort) {
              const currentSortKey = sort.type
                .replace(/-/g, "")
                .concat(sort.order) as AttributeSortKeys;
              updateCurrentQBInfo(searchType, "sortKey", currentSortKey);
            }
          }

          setIsLoading(false);
          setSuccess(true);
        });
      } else {
        setIsLoading(false);
        setSuccess(true);
      }
    } catch (err) {
      const error = err as Error;
      console.debug("getAttributeCount error", error);
      setError(error?.message);
      setIsLoading(false);
      setSuccess(false);
    }
  };

  return {
    isLoading,
    error,
    success,
    getAttributeCount,
  };
};

export default useAttributeCountData;
