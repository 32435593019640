import { Axis, AxisType, Dash, LayoutAxis } from "plotly.js";

import {
  ChartAxisType,
  ChartRange,
  YAxisScaleType,
} from "../../../types/charts/chartType/chartType";

import { DATE } from "../../../constants/attributes";
import {
  CHART_AXIS,
  CHART_SCALING_TYPE,
  mmmYYYYTickFormat,
} from "../../../constants/charts/charts";

const gridDash: Dash = "dot";

export const config = {
  responsive: true,
  displayModeBar: false,
  edits: {
    legendPosition: true,
  },
};

export const generalLayout = (
  isForFullScreenView: boolean,
  hasMultipleYAxis: boolean
) => {
  return {
    autosize: true,
    paper_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    plot_bgcolor: !isForFullScreenView ? "rgba(0,0,0,0)" : "#272727",
    showlegend: hasMultipleYAxis,
  };
};

export const marginLayout = {
  t: 55,
  b: 40,
  l: 10,
  r: 10,
};

export const fontLayout = {
  size: 12,
  family: "Roboto, Arial",
};

export const legendLayout = (
  range: ChartRange | undefined,
  isForFullScreenView: boolean,
  legendTitle: string
) => {
  return {
    x: range?.xLegend ? range?.xLegend : isForFullScreenView ? 1.039 : 1.25,
    ...(range?.yLegend && {
      y: range?.yLegend,
    }),
    title: {
      text: legendTitle,
      font: {
        family: "Roboto, Arial",
        size: 12,
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    font: {
      color: "#838383",
      size: 12,
    },
    bgcolor: "rgba(0,0,0,0)",
  };
};

export const xAxisLayout = (
  range: ChartRange | undefined,
  xAxisTitle: string | undefined,
  dataType: string | undefined,
  shouldFormatXTicks: boolean
) => {
  const isValid = (currentValue: number | undefined | null) =>
    currentValue !== undefined && currentValue !== null;
  const hasRange =
    range && range?.xRange && (range?.xRange as []).every(isValid);
  return {
    title: {
      text: xAxisTitle,
      font: {
        family: "Roboto, Arial",
        color: "rgba(117, 117, 117, 1)",
        size: 12,
      },
      standoff: 10,
    },
    automargin: true,
    color: "rgba(255, 255, 255, 0.7)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: gridDash,
    gridcolor: "rgba(117, 117, 117, 1)",
    ...(hasRange
      ? {
          range: range.xRange,
          autorange: false,
        }
      : { autorange: true }),
    ...(dataType === DATE
      ? shouldFormatXTicks
        ? { tickformat: mmmYYYYTickFormat, ticklabelstep: 5 }
        : { tickformat: mmmYYYYTickFormat, tickangle: 45 }
      : {}),
  };
};
export const yAxisLayout = (
  scaling: YAxisScaleType | undefined,
  range: ChartRange | undefined,
  tick: number[],
  yAxisTitle: string | undefined,
  axis: ChartAxisType
) => {
  const mode: "auto" | "linear" | "array" = "array";
  const showexponent: "all" | "first" | "last" | "none" = "none";
  const type: AxisType = "log";
  const exponentformat: "none" | "e" | "E" | "power" | "SI" | "B" = "power";
  const isValid = (currentValue: number | undefined | null) =>
    currentValue !== undefined && currentValue !== null;
  const yRangeVal = axis === CHART_AXIS.LEFT ? range?.yRange : range?.yRange2;
  const hasRange = range && yRangeVal && (yRangeVal as []).every(isValid);

  return {
    ...(hasRange
      ? {
          range: yRangeVal,
          autorange: false,
        }
      : { autorange: true }),
    hoverformat: ",.0f",
    automargin: true,
    title: {
      text: yAxisTitle,
      font: {
        family: "Roboto, Arial",
        color: "rgba(117, 117, 117, 1)",
        size: 12,
      },
    },
    showline: true,
    color: "rgba(255, 255, 255, 0.7)",
    gridcolor: "rgba(117, 117, 117, 1)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: gridDash,
    ...(scaling === CHART_SCALING_TYPE.LOGARITHMIC && {
      type: type,
      showexponent: showexponent,
      exponentformat: exponentformat,
    }),
    rangemode: "normal",
  } as LayoutAxis;
};
