import { useCallback, useEffect, useMemo, useState } from "react";

import { ActivePanelKeys } from "../../types/panels/sidebar";

import { SIDEBAR_MENU_TEXTS } from "../../constants/panels/menu";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import usePanelsStore from "../../store/panels/panelsStore";

import { sortWellsDesc } from "../../utils/panel/wellPanel/sorting";

const useSideBarNavPanel = () => {
  const [isExportDisabled, setIsExportDisabled] = useState(true);

  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );
  const updateActiveWellPanel = usePanelsStore(
    (state) => state.updateActiveWellPanel
  );
  const activePanel = usePanelsStore((state) => state.activePanel);
  const wellInfoList = usePanelsStore((state) => state.wellList);
  const permitsInfoList = usePanelsStore((state) => state.permitsList);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const updateActivePermitPanel = usePanelsStore(
    (state) => state.updateActivePermitPanel
  );
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );

  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);
  const allWellGridDataLoading = useDataGridStore(
    (state) => state.allWellGridDataLoading
  );

  const updateExportPanelMenuTrigger = useExportCardStore(
    (state) => state.updateExportPanelMenuTrigger
  );
  const exportManagerLoading = useExportCardStore(
    (state) => state.exportManagerLoading
  );

  const wellsAndPermitPanel = useMemo(() => {
    return [...wellInfoList, ...permitsInfoList];
  }, [
    wellInfoList,
    permitsInfoList,
    wellInfoList.length,
    permitsInfoList.length,
  ]);

  const getIsDisabledMenu = (menuText: ActivePanelKeys) => {
    switch (menuText) {
      case SIDEBAR_MENU_TEXTS.EXPORT_PACKAGE_MANAGER:
        if (isExportDisabled || exportManagerLoading) return true;
        return false;
      default:
        return false;
    }
  };

  const handleMenuClick = (menuText: ActivePanelKeys) => {
    if (menuText === SIDEBAR_MENU_TEXTS.EXPORT_PACKAGE_MANAGER) {
      updateExportPanelMenuTrigger(true);
    } else {
      if (activePanel === SIDEBAR_MENU_TEXTS.WELL_PANEL) {
        updateActiveWellPanel(false, undefined);
      }
      updateExportPanelMenuTrigger(false);

      if (activePanel !== menuText) {
        updateActivePanel(menuText);
        toggleLeftSidePanel(true);
      } else {
        toggleLeftSidePanel();
      }
    }
  };

  const handleCloseMenuClick = () => {
    toggleLeftSidePanel();
  };

  const isMenuTextOpen = useCallback(
    (menuText: ActivePanelKeys) => {
      return Boolean(
        isOpenAppLevelNav && isOpenLeftSidePanel && menuText === activePanel
      );
    },
    [isOpenAppLevelNav, isOpenLeftSidePanel, activePanel]
  );

  const openSidebarPanel = useMemo(() => {
    return {
      dashboardPanel: isMenuTextOpen(SIDEBAR_MENU_TEXTS.DEFAULT_CHARTS),
      searchPanel: isMenuTextOpen(SIDEBAR_MENU_TEXTS.SEARCH),
      savedSearchPanel: isMenuTextOpen(SIDEBAR_MENU_TEXTS.SAVED_SEARCH),
      wellPanel: isMenuTextOpen(SIDEBAR_MENU_TEXTS.WELL_PANEL),
      exportManagerPanel: isMenuTextOpen(
        SIDEBAR_MENU_TEXTS.EXPORT_PACKAGE_MANAGER
      ),
    };
  }, [activePanel, isOpenAppLevelNav, isOpenLeftSidePanel]);

  const sortedWellsAndPermitPanel = useMemo(
    () =>
      wellsAndPermitPanel.length ? sortWellsDesc(wellsAndPermitPanel) : [],
    [wellsAndPermitPanel, wellsAndPermitPanel.length]
  );

  useEffect(() => {
    if (gridTotalCount > 0 && !allWellGridDataLoading) {
      setIsExportDisabled(false);
    } else {
      setIsExportDisabled(true);
    }
  }, [gridTotalCount, allWellGridDataLoading]);

  useEffect(() => {
    if (sortedWellsAndPermitPanel.length > 0) {
      const nonActive = sortedWellsAndPermitPanel.every(
        (wellPermitData) => !wellPermitData.isActive
      );

      if (nonActive) {
        if (sortedWellsAndPermitPanel[0].recordType === RECORD_TYPES.PERMIT) {
          updateActivePermitPanel(true, sortedWellsAndPermitPanel[0]);
        } else if (
          sortedWellsAndPermitPanel[0].recordType === RECORD_TYPES.WELL
        ) {
          updateActiveWellPanel(true, sortedWellsAndPermitPanel[0]);
        }
      }
    }
  }, [sortedWellsAndPermitPanel, sortedWellsAndPermitPanel.length]);

  return {
    openSidebarPanel,
    isExportDisabled,
    wellsAndPermitPanel,
    sortedWellsAndPermitPanel,
    handleMenuClick,
    handleCloseMenuClick,
    getIsDisabledMenu,
  };
};

export default useSideBarNavPanel;
