import { useState } from "react";

import axios from "axios";

import {
  CreateSavedSearch,
  UpdateSavedSearch,
} from "../../../types/panels/savedSearchPanel/hooks";
import { SavedSearchDataResponse } from "../../../types/panels/savedSearchPanel/savedSearchData";

import config from "../../../configs/appSettings";

import {
  CREATE_SAVED_SEARCH,
  UPDATE_SAVED_SEARCH,
} from "../../../constants/panels/savedSearchPanel/hooks";

import useStore from "../../../store/useStore";

import { getIsAutoSaveSearchType } from "../../../utils/savedSearch/savedData";

import useSavedSearchHelper from "../useSavedSearchHelper";
import useSavedSearchData from "./useSavedSearchData";
import useSavedSearchExportData from "./useSavedSearchExportData";

const useCreateUpdateSavedSearch = () => {
  const authUser = useStore((state) => state.authUser);
  const updateSavedSearchFileName = useStore(
    (state) => state.updateSavedSearchFileName
  );
  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );
  const updateSelectedSavedSearchData = useStore(
    (state) => state.updateSelectedSavedSearchData
  );

  const { error, setError, catchError } = useSavedSearchHelper();
  const { savedSearchData } = useSavedSearchData();
  const { savedSearchExportData } = useSavedSearchExportData();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SavedSearchDataResponse | null>(null);

  const createSavedSearch: CreateSavedSearch = (
    saveName,
    searchType,
    isFilterIncluded,
    isAutoSave,
    onClickSaveCallback
  ) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const sapCustomerId = authUser.SapCustomerId ?? null;
    const { searchData, searchTypeToBeSaved } = savedSearchData({
      action: CREATE_SAVED_SEARCH,
      searchType,
      hasLoadedAndUpdatedSearch: !isFilterIncluded,
    });

    const searchExportData = config.isScheduledExportsEnabled
      ? savedSearchExportData(searchType)
      : {};

    const body = {
      userId: `${authUser.uid}`,
      projectId: null,
      applicationName: "Saga-Analytics",
      name: saveName,
      templateType: "WithData",
      searchType: searchTypeToBeSaved,
      searchName: saveName,
      autoSave: isAutoSave,
      visibility: "Private",
      activeFlag: "true",
      creationTime: "",
      updatedTime: "",
      searchData,
      sapCustomerId,
      ...searchExportData,
    };

    axios
      .post<SavedSearchDataResponse>(
        `${config.endpoints.wellService}api/searches`,
        body
      )
      .then(({ data }) => {
        setData(data);
        onClickSaveCallback();
        updateSavedSearchFileName(saveName);
        updateSelectedSavedSearchData(data);
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  const updateSavedSearch: UpdateSavedSearch = (searchType) => {
    if (!selectedSavedSearchData) return;
    setIsLoading(true);
    setData(null);
    setError(null);

    const { searchData } = savedSearchData({
      action: UPDATE_SAVED_SEARCH,
      searchType,
    });

    const searchExportData = config.isScheduledExportsEnabled
      ? savedSearchExportData(searchType)
      : {};

    const hasAuto = getIsAutoSaveSearchType(selectedSavedSearchData.searchType);

    const body = {
      userId: `${authUser.uid}`,
      searchName: selectedSavedSearchData.searchName,
      searchType: `${searchType}${hasAuto ? "-Auto" : ""}`,
      searchData,
      lastRunTime: "true",
      ...searchExportData,
    };

    axios
      .post<SavedSearchDataResponse>(
        `${config.endpoints.wellService}api/searches/update/${selectedSavedSearchData.id}`,
        body
      )
      .then(({ data }) => {
        setData(data);
        updateSelectedSavedSearchData(data);
      })
      .catch((error) => catchError(error))
      .finally(() => setIsLoading(false));
  };

  return {
    isLoading,
    data,
    error,
    createSavedSearch,
    updateSavedSearch,
  };
};

export default useCreateUpdateSavedSearch;
